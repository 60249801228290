export function sanitizeInput(word: string) {
    return word.toLowerCase().replace(/[\s!'",.?():;-]/g, '')
}

export function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
}

export function getScreenWidth() {
    return window.innerWidth

}
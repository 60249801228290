export const sideBarPixels = 60;

export const lyricContainerPixels = 80;
export const lyricContainerMobile = 65;
export const gridColumnGap = 10;

// why is this in the style consts?? thats bc idk where it should go
export const debounceMS = 180;


export const colors = {
  debut: '#0ca3db',
  fearless: '#ba9d45',
  red: '#951e1a',
  speak_now: '#442755',
  nineteen89: '#9dbbce',
  reputation: '#404040',
  lover: '#f262c8',
  folklore: '#7f7f7f',
  evermore: '#be7635',
  midnights: '#526d85',
  ttpd: '#968E83',
  eras_tour: '#a393eb',
  black: '#000',
}

export const fonts = {
  debut: 'Tangerine',
  fearless: "'Roboto Condensed'",
  red: "'Roboto Condensed'",
  speak_now: 'Tangerine',
  nineteen89: "'Patrick Hand'",
  nineteen89_backup: 'cursive',
  reputation: 'chomskyregular',
  lover: 'pinyon_script',
  folklore: 'im_fell_dw_picaitalic',
  evermore: 'im_fell_dw_picaitalic',
  midnights: 'Roboto',
  ttpd: 'Roboto',
  eras_tour: 'pistilli-roman',
  serif: 'serif',
  sans_serif: 'sans-serif',
}
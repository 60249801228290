import React from 'react';
import styled from 'styled-components';
import { Footer } from '../../Footer';
import SEO from '../../SEO';
import { SEOData } from '../../SEO/data';

const StyledBody = styled.body`
  margin: 0;
  padding: 0;
  background-color: #526d85;
  font-family: arial
  min-height: 100vh;
`

const StyledBox = styled.div`
  margin:0 10%;
  overflow: scroll;
  padding: 10px 0 40px 60px
`

const StyledHeader = styled.h1`
  color: white;
  text-transform: uppercase;
  padding-bottom: 20px;
  padding-left: 60px;
`

const StyledList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
  transition: all 0.5s linear;
  top:0;

  &:before {
    content: "";
    display: block;
    width: 0;
    height: 100%;
    border:1px dashed #fff;
    position: absolute;
    top:0;
    left:30px
  }
`
const StyledSpan = styled.span`
  color:#fff;
  font-size:80%;
  font-weight: bold;
  content: "";
  display: block;
  width: 0;
  height: 100%;
  border:1px solid #fff;
  position: absolute;
  top:0;
  left:-30px;

  &:before, &:after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    
    border:2px solid #fff;
    position: absolute;
    left:-7.5px;
  }

  &:before {
    top:-10px;
    background: white;
  }
  &:after {
    top:95%;
    background: #526d85;
  }
`
const StyledItem = styled.li`
  margin: 20px 60px 60px;
  position: relative;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
  border-radius: 10px;
  line-height: 20px;
  width: min(60%, 1000px);
`

const StyledTitle = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 5px;
  left: -100px;
  color: #fff;
  font-size:80%;
  font-weight: bold;
`
const StyledInfo = styled.div``
const StyledTime = styled.div`
  span {
    position: absolute;
    left: -110px;
    color:#fff;
    font-size:80%;
    font-weight: bold;
    top:-12px;
  }
`

const FooterBox = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 15px;
`

function ChangeLog() {
  return <StyledBody>
          <SEO seoObject={SEOData.changeLog}/>

          <StyledBox>
            <StyledHeader>Site Updates</StyledHeader>
          <StyledList>

          <StyledItem>
              <StyledSpan></StyledSpan>
              <StyledTitle>TTPD</StyledTitle>
              <StyledInfo>
                New songs!!!!!!!!!!!
              </StyledInfo>
              <StyledTime>
                <span>April 19, 2024</span>
              </StyledTime>
            </StyledItem>


            <StyledItem>
              <StyledSpan></StyledSpan>
              <StyledTitle>Storing Album Settings</StyledTitle>
              <StyledInfo>
                I now save which albums you had selected so that you don't have to set them up every time you visit the page.
              </StyledInfo>
              <StyledTime>
                <span>Feb 20, 2024</span>
              </StyledTime>
            </StyledItem>

            <StyledItem>
              <StyledSpan></StyledSpan>
              <StyledTitle>Escape</StyledTitle>
              <StyledInfo>
                If you hit the Escape button when typing a word, it will clear out the text and NOT change the size of your browser.
              </StyledInfo>
              <StyledTime>
                <span>Feb 17, 2024</span>
              </StyledTime>
            </StyledItem>

            <StyledItem>
              <StyledSpan></StyledSpan>
              <StyledTitle>Plurals</StyledTitle>
              <StyledInfo>
                When you guess a word, the plural version of the word will be marked as guessed to (e.g., "cut" * "cuts")
              </StyledInfo>
              <StyledTime>
                <span>Feb 06, 2024</span>
              </StyledTime>
            </StyledItem>

            <StyledItem>
              <StyledSpan></StyledSpan>
              <StyledTitle>You can now automatically reveal "Yeah"s and "Whoa"s when you get the titles</StyledTitle>
              <StyledInfo>
                I got a couple of comments that some of the last words they couldn't guess on a song were vocalization words like "mmm" and "ooh-oh-whoa-oh".
                <br/>I added a setting to have those types of words be automatically revealed when you guess the title of a song!
                Let me know what you think!
              </StyledInfo>
              <StyledTime>
                <span>Jan 30, 2024</span>
              </StyledTime>
            </StyledItem>

            <StyledItem>
              <StyledSpan></StyledSpan>
              <StyledTitle>Better Fuzzy Matching</StyledTitle>
              <StyledInfo>
                I updated the word matching code to be a little bit more forgiving :&#41;
                <br/>Before, the song guesser would count different spellings like  "Steven" and "Stephen" as matches.
                Now, it also handles <i>simple</i> typos like "because" with "bec<b>ua</b>se" or "becaus<b>r</b>".
              </StyledInfo>
              <StyledTime>
                  <span>Jan 29, 2024</span>
              </StyledTime>
            </StyledItem>

            <StyledItem>
              <StyledSpan></StyledSpan>
              <StyledTitle>"Song Order" & "Lyric Direction" are saved</StyledTitle>
              <StyledInfo>
                I saved the "Song Order" and "Lyric Direction" values in your browser's cookies.
                When you visit the page again on the same browser the settings will be set to your last setting.
              </StyledInfo>
              <StyledTime>
                  <span>Jan 22, 2024</span>
              </StyledTime>
            </StyledItem>

            <StyledItem>
              <StyledSpan></StyledSpan>
              <StyledTitle>Fixed a bug</StyledTitle>
              <StyledInfo>
                In the past when you'd select a new album and the current song you were playing was <i>not</i> in that album, the song wouldn't change.
                This was confusing for new users beacuse they'd select an album and then the song they were guessing was not what they expected.
                <br/><br/>
                Now, the site will automtically pick a new song if you choose an album list that is differnt than the default song the site picked for you.
                <br/>
                <b>Note:</b> The song will automtically change only if you have not guessed any words before.
                If you're in the middle of playing a song and change the album selection, nothing will happen.
                <br/><br/>
                &#40;If this change is confusing, don't worry about it! :&#41; You probably won't even notice that anything's different.&#41;
              </StyledInfo>
              <StyledTime>
                  <span>Jan 22, 2024</span>
              </StyledTime>
            </StyledItem>


            <StyledItem>
                <StyledSpan></StyledSpan>
                <StyledTitle>Surprise Song Tracker</StyledTitle>
                <StyledInfo>
                  I created a Surprise Songs tracker that shows both 2023's and 2024's surprise songs. It's stil a work in progress, but it's working now with accurate data!
                </StyledInfo>
                <StyledTime>
                    <span>Jan 08, 2024</span>
                </StyledTime>
            </StyledItem>


            <StyledItem>
                <StyledSpan></StyledSpan>
                <StyledTitle>1989 Vault is in the 1989 album</StyledTitle>
                <StyledInfo>
                  As part of some maintence work, I moved the 1989 Vault tracks into the main 1989 album
                </StyledInfo>
                <StyledTime>
                    <span>Jan 08, 2024</span>
                </StyledTime>
            </StyledItem>

            <StyledItem>
                <StyledSpan></StyledSpan>
                <StyledTitle>Change Log Page</StyledTitle>
                <StyledInfo>I added this site update & FAQ page</StyledInfo>                
                <StyledTime>
                    <span>Jan 03, 2024</span>
                </StyledTime>
            </StyledItem>

            <StyledItem>
                <StyledSpan></StyledSpan>
                <StyledTitle>Welcome to New York</StyledTitle>
                <StyledInfo>
                  I added the 1989 vault tracks as a separate, playable album
                </StyledInfo>
                <StyledTime>
                    <span>Oct 27, 2023</span>
                </StyledTime>
            </StyledItem>
            <StyledItem>
                <StyledSpan></StyledSpan>
                <StyledTitle>Keep Your Eyes Open</StyledTitle>
                <StyledInfo>
                  I fixed some lyric typing issue with "ey-eyes" in Keep Your Eyes Open
                </StyledInfo>
                <StyledTime>
                    <span>Oct 25, 2023</span>
                </StyledTime>
            </StyledItem>

            <StyledItem>
                <StyledSpan></StyledSpan>
                <StyledTitle>Extra hints</StyledTitle>
                <StyledInfo>
                  Now, when you click on an empty box, it will revewal the word in that box.
                </StyledInfo>
                <StyledTime>
                    <span>Oct 06, 2023</span>
                </StyledTime>
            </StyledItem>

            <StyledItem>
                <StyledSpan></StyledSpan>
                <StyledTitle>Reveal Album</StyledTitle>
                <StyledInfo>
                  When you click "Reveal Album" the album name will display until you guess the name of the song
                </StyledInfo>
                <StyledTime>
                    <span>Jul 18, 2023</span>
                </StyledTime>
            </StyledItem>    
                
          </StyledList>
        </StyledBox>
        <FooterBox>
          <Footer currentPage='changeLog'/>
        </FooterBox>
      </StyledBody>

} export default ChangeLog;
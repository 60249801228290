import React from 'react';



function Clowning() {
  const text = "var allElements = document.querySelectorAll('*');"
  const text2 = `allElements.forEach(n => {
    const classNames = n.className.toString().split(/\\s+/)
    if (classNames[0] === "") { return }
    console.log(n.nodeType, classNames)
})`

  const text3 = `
[Log] html – ["no-js"] (1)
[Log] script – ["yoast-schema-graph"] (1)
[Log] body – ["home", "page-template-default", "page", "page-id-6", "wp-embed-responsive", "page-home", "umggr-page6", "umggr-mobile-view"] (8)
[Log] div – ["slide-menu-gr-peakaboo--mobile-only"] (1)
[Log] ul – ["slide-menu-gr-child", "slide-menu-gr-child-peekaboo", "peekaboo", "store-sub-menu", "sub-menu-566", ""] (6)
[Log] li – ["slide-menu-gr-item"] (1)
[Log] li – ["slide-menu-gr-item"] (1)
[Log] li – ["slide-menu-gr-item"] (1)
[Log] header – ["site-header", "site-header"] (2)
[Log] div – ["flex-wrap", "flex"] (2)
[Log] div – ["flex-item", "flex", "-dynamic-header", "-top-left"] (4)
[Log] div – ["main-nav", "flex-item"] (2)
[Log] nav – ["slide-menu-gr-container", "slide-menu-gr-container--mobile-only"] (2)
[Log] div – ["menu-main-container"] (1)
[Log] ul – ["flex", "slide-menu-gr-parent", "slide-menu-gr-parent--mobile-only"] (3)
[Log] li – ["nav-btn", "menu-item", "menu-item-type-post_type", "menu-item-object-page", "menu-item-has-children", "slide-menu-gr-item", "with-children"] (7)
[Log] a – ["open-slide-menu-gr"] (1)
[Log] ul – ["slide-menu-gr-child", "mobile"] (2)
[Log] li – ["nav-subscribe", "menu-item", "menu-item-type-custom", "menu-item-object-custom", "current-menu-item", "current_page_item", "slide-menu-gr-item"] (7)
[Log] a – ["open-slide-menu-gr"] (1)
[Log] ul – ["slide-menu-gr-child", "mobile"] (2)
[Log] li – ["facebook", "menu-item", "menu-item-type-custom", "menu-item-object-custom", "slide-menu-gr-item"] (5)
[Log] li – ["instagram", "menu-item", "menu-item-type-custom", "menu-item-object-custom", "slide-menu-gr-item"] (5)
[Log] li – ["tumblr", "menu-item", "menu-item-type-custom", "menu-item-object-custom", "slide-menu-gr-item"] (5)
[Log] li – ["twitter", "menu-item", "menu-item-type-custom", "menu-item-object-custom", "slide-menu-gr-item"] (5)
[Log] li – ["tiktok", "menu-item", "menu-item-type-custom", "menu-item-object-custom", "slide-menu-gr-item"] (5)
[Log] li – ["youtube", "menu-item", "menu-item-type-custom", "menu-item-object-custom", "slide-menu-gr-item"] (5)
[Log] li – ["snapchat", "menu-item", "menu-item-type-custom", "menu-item-object-custom", "slide-menu-gr-item"] (5)
[Log] li – ["menu-item", "slide-menu-gr-item", "menu-item-social"] (3)
[Log] svg – ["[object", "SVGAnimatedString]"] (2)
[Log] title – ["[object", "SVGAnimatedString]"] (2)
[Log] desc – ["[object", "SVGAnimatedString]"] (2)
[Log] path – ["[object", "SVGAnimatedString]"] (2)
[Log] li – ["menu-item", "slide-menu-gr-item", "menu-item-social"] (3)
[Log] svg – ["[object", "SVGAnimatedString]"] (2)
[Log] title – ["[object", "SVGAnimatedString]"] (2)
[Log] desc – ["[object", "SVGAnimatedString]"] (2)
[Log] path – ["[object", "SVGAnimatedString]"] (2)
[Log] li – ["menu-item", "slide-menu-gr-item", "menu-item-social"] (3)
[Log] svg – ["[object", "SVGAnimatedString]"] (2)
[Log] title – ["[object", "SVGAnimatedString]"] (2)
[Log] desc – ["[object", "SVGAnimatedString]"] (2)
[Log] path – ["[object", "SVGAnimatedString]"] (2)
[Log] li – ["menu-item", "slide-menu-gr-item", "menu-item-social"] (3)
[Log] svg – ["[object", "SVGAnimatedString]"] (2)
[Log] title – ["[object", "SVGAnimatedString]"] (2)
[Log] desc – ["[object", "SVGAnimatedString]"] (2)
[Log] path – ["[object", "SVGAnimatedString]"] (2)
[Log] li – ["menu-item", "slide-menu-gr-item", "menu-item-social"] (3)
[Log] svg – ["[object", "SVGAnimatedString]"] (2)
[Log] title – ["[object", "SVGAnimatedString]"] (2)
[Log] desc – ["[object", "SVGAnimatedString]"] (2)
[Log] path – ["[object", "SVGAnimatedString]"] (2)
[Log] li – ["menu-item", "slide-menu-gr-item", "menu-item-social"] (3)
[Log] svg – ["[object", "SVGAnimatedString]"] (2)
[Log] title – ["[object", "SVGAnimatedString]"] (2)
[Log] desc – ["[object", "SVGAnimatedString]"] (2)
[Log] g – ["[object", "SVGAnimatedString]"] (2)
[Log] path – ["[object", "SVGAnimatedString]"] (2)
[Log] li – ["menu-item", "slide-menu-gr-item", "menu-item-social"] (3)
[Log] svg – ["[object", "SVGAnimatedString]"] (2)
[Log] title – ["[object", "SVGAnimatedString]"] (2)
[Log] desc – ["[object", "SVGAnimatedString]"] (2)
[Log] path – ["[object", "SVGAnimatedString]"] (2)
[Log] div – ["dimmer-lay"] (1)
[Log] div – ["flex-item", "flex", "-dynamic-header", "-middle"] (4)
[Log] div – ["artist-logo", "flex-item"] (2)
[Log] h1 – ["-is-visually-hidden"] (1)
[Log] picture – ["header-logo-mobile"] (1)
[Log] picture – ["header-logo-desktop"] (1)
[Log] div – ["flex-item", "flex", "-dynamic-header", "-top-right"] (4)
[Log] div – ["social-nav", "flex-item"] (2)
[Log] nav – ["slide-menu-gr-container", "slide-menu-gr-container--mobile-only"] (2)
[Log] div – ["menu-social-container"] (1)
[Log] ul – ["flex", "slide-menu-gr-parent", "slide-menu-gr-parent--mobile-only", "social-menu--mobile-only"] (4)
[Log] li – ["facebook", "menu-item", "menu-item-type-custom", "menu-item-object-custom", "slide-menu-gr-item"] (5)
[Log] svg – ["[object", "SVGAnimatedString]"] (2)
[Log] title – ["[object", "SVGAnimatedString]"] (2)
[Log] desc – ["[object", "SVGAnimatedString]"] (2)
[Log] path – ["[object", "SVGAnimatedString]"] (2)
[Log] li – ["instagram", "menu-item", "menu-item-type-custom", "menu-item-object-custom", "slide-menu-gr-item"] (5)
[Log] svg – ["[object", "SVGAnimatedString]"] (2)
[Log] title – ["[object", "SVGAnimatedString]"] (2)
[Log] desc – ["[object", "SVGAnimatedString]"] (2)
[Log] path – ["[object", "SVGAnimatedString]"] (2)
[Log] li – ["tumblr", "menu-item", "menu-item-type-custom", "menu-item-object-custom", "slide-menu-gr-item"] (5)
[Log] svg – ["[object", "SVGAnimatedString]"] (2)
[Log] title – ["[object", "SVGAnimatedString]"] (2)
[Log] desc – ["[object", "SVGAnimatedString]"] (2)
[Log] path – ["[object", "SVGAnimatedString]"] (2)
[Log] li – ["twitter", "menu-item", "menu-item-type-custom", "menu-item-object-custom", "slide-menu-gr-item"] (5)
[Log] svg – ["[object", "SVGAnimatedString]"] (2)
[Log] title – ["[object", "SVGAnimatedString]"] (2)
[Log] desc – ["[object", "SVGAnimatedString]"] (2)
[Log] path – ["[object", "SVGAnimatedString]"] (2)
[Log] li – ["tiktok", "menu-item", "menu-item-type-custom", "menu-item-object-custom", "slide-menu-gr-item"] (5)
[Log] svg – ["[object", "SVGAnimatedString]"] (2)
[Log] title – ["[object", "SVGAnimatedString]"] (2)
[Log] desc – ["[object", "SVGAnimatedString]"] (2)
[Log] path – ["[object", "SVGAnimatedString]"] (2)
[Log] li – ["youtube", "menu-item", "menu-item-type-custom", "menu-item-object-custom", "slide-menu-gr-item"] (5)
[Log] svg – ["[object", "SVGAnimatedString]"] (2)
[Log] title – ["[object", "SVGAnimatedString]"] (2)
[Log] desc – ["[object", "SVGAnimatedString]"] (2)
[Log] g – ["[object", "SVGAnimatedString]"] (2)
[Log] path – ["[object", "SVGAnimatedString]"] (2)
[Log] li – ["snapchat", "menu-item", "menu-item-type-custom", "menu-item-object-custom", "slide-menu-gr-item"] (5)
[Log] svg – ["[object", "SVGAnimatedString]"] (2)
[Log] title – ["[object", "SVGAnimatedString]"] (2)
[Log] desc – ["[object", "SVGAnimatedString]"] (2)
[Log] path – ["[object", "SVGAnimatedString]"] (2)
[Log] div – ["dimmer-lay"] (1)
[Log] div – ["main-nav", "flex-item"] (2)
[Log] div – ["mobile-nav-header", "slide-menu-toggle"] (2)
[Log] div – ["home"] (1)
[Log] section – ["promo", "home", ""] (3)
[Log] div – ["content-type-blocks"] (1)
[Log] div – ["container", "gradient-bg"] (2)
[Log] div – ["t-row-column-wrap", "dt-has-1-columns", "pt-musas-default", "pt-d-tinta-default", "pd-row-buamaí-grá-layout"] (5)
[Log] div – ["dp-block-aders-column", "inner-column-d", "talismani-column-tpd", "kadence-column_47d-32p1"] (4)
[Log] div – ["1-column", "vorsitzende"] (2)
[Log] div – ["2-column", "siniaki"] (2)
[Log] div – ["site-footer"] (1)
[Log] div – ["legal"] (1)
[Log] a – ["push-global-ccpa"] (1)
[Log] a – ["ot-sdk-show-settings"] (1)
[Log] span – ["umggr-evidon-cookie-choices-footer-container"] (1)
[Log] span – ["umggr-evidon-cookie-choices-footer"] (1)
[Log] div – ["footer-links"] (1)
[Log] div – ["flex", "flex-wrap"] (2)
[Log] div – ["footer-socials", "flex"] (2)
[Log] div – ["menu-social-container"] (1)
[Log] ul – ["footer-social-menu", "flex"] (2)
[Log] li – ["facebook", "menu-item", "menu-item-type-custom", "menu-item-object-custom", "slide-menu-gr-item"] (5)
[Log] svg – ["[object", "SVGAnimatedString]"] (2)
[Log] title – ["[object", "SVGAnimatedString]"] (2)
[Log] desc – ["[object", "SVGAnimatedString]"] (2)
[Log] path – ["[object", "SVGAnimatedString]"] (2)
[Log] li – ["instagram", "menu-item", "menu-item-type-custom", …] (5)
["instagram", "menu-item", "menu-item-type-custom", "menu-item-object-custom", "slide-menu-gr-item"] (5)
[Log] svg – ["[object", "SVGAnimatedString]"] (2)
[Log] title – ["[object", "SVGAnimatedString]"] (2)
[Log] desc – ["[object", "SVGAnimatedString]"] (2)
[Log] path – ["[object", "SVGAnimatedString]"] (2)
[Log] li – ["tumblr", "menu-item", "menu-item-type-custom", "menu-item-object-custom", "slide-menu-gr-item"] (5)
[Log] svg – ["[object", "SVGAnimatedString]"] (2)
[Log] title – ["[object", "SVGAnimatedString]"] (2)
[Log] desc – ["[object", "SVGAnimatedString]"] (2)
[Log] path – ["[object", "SVGAnimatedString]"] (2)
[Log] li – ["twitter", "menu-item", "menu-item-type-custom", "menu-item-object-custom", "slide-menu-gr-item"] (5)
[Log] svg – ["[object", "SVGAnimatedString]"] (2)
[Log] title – ["[object", "SVGAnimatedString]"] (2)
[Log] desc – ["[object", "SVGAnimatedString]"] (2)
[Log] path – ["[object", "SVGAnimatedString]"] (2)
[Log] li – [["tiktok", "menu-item", "menu-item-type-custom", "menu-item-object-custom", "slide-menu-gr-item"] (5)
[Log] svg – ["[object", "SVGAnimatedString]"] (2)
[Log] title – ["[object", "SVGAnimatedString]"] (2)
[Log] desc – ["[object", "SVGAnimatedString]"] (2)
[Log] path – ["[object", "SVGAnimatedString]"] (2)
[Log] li – ["youtube", "menu-item", "menu-item-type-custom", "menu-item-object-custom", "slide-menu-gr-item"] (5)
[Log] svg – ["[object", "SVGAnimatedString]"] (2)
[Log] title – ["[object", "SVGAnimatedString]"] (2)
[Log] desc – ["[object", "SVGAnimatedString]"] (2)
[Log] g – ["[object", "SVGAnimatedString]"] (2)
[Log] path – ["[object", "SVGAnimatedString]"] (2)
[Log] li – ["snapchat", "menu-item", "menu-item-type-custom", "menu-item-object-custom", "slide-menu-gr-item"] (5)
[Log] svg – ["[object", "SVGAnimatedString]"] (2)
[Log] title – ["[object", "SVGAnimatedString]"] (2)
[Log] desc – ["[object", "SVGAnimatedString]"] (2)
[Log] path – ["[object", "SVGAnimatedString]"] (2)
[Log] div – ["footer-legal"] (1)
[Log] div – ["flex", "flex-wrap"] (2)
[Log] div – ["copyright", ""] (2)
[Log] span – ["copyright-date"] (1)
[Log] div – ["legal"] (1)
[Log] a – ["push-global-ccpa"] (1)
[Log] a – ["ot-sdk-show-settings"] (1)
[Log] span – ["umggr-evidon-cookie-choices-footer-container"] (1)
[Log] span – ["umggr-evidon-cookie-choices-footer"] (1)
[Log] div – ["footer-custom-items", "flex"] (2)
[Log] div – ["footer-custom-item", "flex"] (2)
[Log] div – ["grecaptcha-badge"] (1)
[Log] div – ["grecaptcha-logo"] (1)
[Log] div – ["grecaptcha-error"] (1)
[Log] textarea – ["g-recaptcha-response"] (1)
[Log] div – ["onetrust-pc-dark-filter", "ot-hide", "ot-fade-in"] (3)
[Log] div – ["otPcTab", "ot-hide", "ot-fade-in", "ot-addtl-vendors"] (4)
[Log] div – ["ot-pc-header"] (1)
[Log] div – ["ot-pc-logo"] (1)
[Log] div – ["ot-title-cntr"] (1)
[Log] div – ["ot-close-cntr"] (1)
[Log] button – ["ot-close-icon"] (1)
[Log] div – ["ot-pc-scrollbar", "ot-sdk-row"] (2)
[Log] div – ["ot-optout-signal", "ot-hide"] (2)
[Log] div – ["ot-optout-icon"] (1)
[Log] svg – ["[object", "SVGAnimatedString]"] (2)
[Log] path – ["[object", "SVGAnimatedString]"] (2)
[Log] div – ["ot-sdk-container", "ot-grps-cntr", "ot-sdk-column"] (3)
[Log] div – ["ot-sdk-four", "ot-sdk-columns", "ot-tab-list"] (3)
[Log] ul – ["ot-cat-grp"] (1)
[Log] li – ["ot-abt-tab"] (1)
[Log] div – ["ot-active-menu", "category-menu-switch-handler"] (2)
[Log] li – ["ot-cat-item", "ot-always-active-group", "ot-vs-config"] (3)
[Log] div – ["category-menu-switch-handler"] (1)
[Log] li – ["ot-cat-item", "ot-vs-config"] (2)
[Log] div – ["category-menu-switch-handler"] (1)
[Log] li – ["ot-cat-item", "ot-vs-config"] (2)
[Log] div – ["category-menu-switch-handler"] (1)
[Log] li – ["ot-cat-item", "ot-vs-config"] (2)
[Log] div – ["category-menu-switch-handler"] (1)
[Log] div – ["ot-tab-desc", "ot-sdk-eight", "ot-sdk-columns"] (3)
[Log] div – ["ot-desc-cntr"] (1)
[Log] p – ["ot-grp-desc"] (1)
[Log] a – ["privacy-notice-link"] (1)
[Log] div – ["ot-desc-cntr", "ot-hide", "ot-always-active-group"] (3)
[Log] div – ["ot-grp-hdr1"] (1)
[Log] div – ["ot-chkbox"] (1)
[Log] input – ["category-switch-handler"] (1)
[Log] span – ["ot-label-txt"] (1)
[Log] h4 – ["ot-cat-header"] (1)
[Log] div – ["ot-tgl-cntr"] (1)
[Log] div – ["ot-always-active"] (1)
[Log] p – ["ot-grp-desc", "ot-category-desc"] (2)
[Log] div – ["ot-hlst-cntr"] (1)
[Log] button – ["ot-link-btn", "category-host-list-handler"] (2)
[Log] div – ["ot-desc-cntr", "ot-hide"] (2)
[Log] div – ["ot-grp-hdr1"] (1)
[Log] div – ["ot-chkbox"] (1)
[Log] input – ["category-switch-handler"] (1)
[Log] span – ["ot-label-txt"] (1)
[Log] h4 – ["ot-cat-header"] (1)
[Log] div – ["ot-tgl-cntr"] (1)
[Log] p – ["ot-grp-desc", "ot-category-desc"] (2)
[Log] div – ["ot-hlst-cntr"] (1)
[Log] button – ["ot-link-btn", "category-host-list-handler"] (2)
[Log] div – ["ot-desc-cntr", "ot-hide"] (2)
[Log] div – ["ot-grp-hdr1"] (1)
[Log] div – ["ot-chkbox"] (1)
[Log] input – ["category-switch-handler"] (1)
[Log] span – ["ot-label-txt"] (1)
[Log] h4 – ["ot-cat-header"] (1)
[Log] div – ["ot-tgl-cntr"] (1)
[Log] p – ["ot-grp-desc", "ot-category-desc"] (2)
[Log] div – ["ot-hlst-cntr"] (1)
[Log] button – ["ot-link-btn", "category-host-list-handler"] (2)
[Log] div – ["ot-desc-cntr", "ot-hide"] (2)
[Log] div – ["ot-grp-hdr1"] (1)
[Log] div – ["ot-chkbox"] (1)
[Log] input – ["category-switch-handler"] (1)
[Log] span – ["ot-label-txt"] (1)
[Log] h4 – ["ot-cat-header"] (1)
[Log] div – ["ot-tgl-cntr"] (1)
[Log] p – ["ot-grp-desc", "ot-category-desc"] (2)
[Log] div – ["ot-hlst-cntr"] (1)
[Log] button – ["ot-link-btn", "category-host-list-handler"] (2)
[Log] section – ["ot-hide", "ot-enbl-chr"] (2)
[Log] div – ["ot-lst-cntr", "ot-pc-scrollbar"] (2)
[Log] button – ["ot-link-btn", "back-btn-handler"] (2)
[Log] svg – ["[object", "SVGAnimatedString]"] (2)
[Log] title – ["[object", "SVGAnimatedString]"] (2)
[Log] g – ["[object", "SVGAnimatedString]"] (2)
[Log] path – ["[object", "SVGAnimatedString]"] (2)
[Log] div – ["ot-lst-subhdr"] (1)
[Log] p – ["ot-scrn-rdr"] (1)
[Log] svg – ["[object", "SVGAnimatedString]"] (2)
[Log] path – ["[object", "SVGAnimatedString]"] (2)
[Log] svg – ["[object", "SVGAnimatedString]"] (2)
[Log] title – ["[object", "SVGAnimatedString]"] (2)
[Log] g – ["[object", "SVGAnimatedString]"] (2)
[Log] path – ["[object", "SVGAnimatedString]"] (2)
[Log] section – ["ot-pc-scrollbar"] (1)
[Log] div – ["ot-sdk-row"] (1)
[Log] div – ["ot-sdk-column"] (1)
[Log] div – ["ot-sel-all"] (1)
[Log] div – ["ot-sel-all-hdr"] (1)
[Log] span – ["ot-consent-hdr"] (1)
[Log] span – ["ot-li-hdr"] (1)
[Log] div – ["ot-sel-all-chkbox"] (1)
[Log] div – ["ot-chkbox"] (1)
[Log] span – ["ot-label-txt"] (1)
[Log] span – ["ot-label-status"] (1)
[Log] div – ["ot-chkbox"] (1)
[Log] span – ["ot-label-txt"] (1)
[Log] span – ["ot-label-status"] (1)
[Log] div – ["ot-chkbox"] (1)
[Log] span – ["ot-label-txt"] (1)
[Log] span – ["ot-label-status"] (1)
[Log] div – ["ot-fltr-scrlcnt", "ot-pc-scrollbar"] (2)
[Log] div – ["ot-fltr-opts"] (1)
[Log] div – ["ot-fltr-opt"] (1)
[Log] div – ["ot-chkbox"] (1)
[Log] input – ["category-filter-handler"] (1)
[Log] span – ["ot-label-txt"] (1)
[Log] span – ["ot-label-status"] (1)
[Log] div – ["ot-fltr-btns"] (1)
[Log] div – ["ot-pc-footer"] (1)
[Log] div – ["ot-btn-container"] (1)
[Log] button – ["save-preference-btn-handler", "onetrust-close-btn-handler"] (2)
[Log] div – ["ot-btn-subcntr"] (1)
[Log] button – ["ot-pc-refuse-all-handler"] (1)
[Log] div – ["ot-pc-footer-logo"] (1)
[Log] span – ["ot-scrn-rdr"] (1)
[Log] iframe – ["ot-text-resize"] (1)
  `

  const uniqClassNames = `
  • "no-js"
• ""
• "-dynamic-header"
• "-is-visually-hidden"
• "-middle"
• "-top-left"
• "-top-right"
• "1-column"
• "2-column"
• "SVGAnimatedString]"
• "[object"
• "artist-logo"
• "back-btn-handler"
• "category-filter-handler"
• "category-host-list-handler"
• "category-menu-switch-handler"
• "category-switch-handler"
• "container"
• "content-type-blocks"
• "copyright"
• "copyright-date"
• "current-menu-item"
• "current_page_item"
• "dimmer-lay"
• "dp-block-aders-column"
• "dt-has-1-columns"
• "facebook"
• "flex"
• "flex-item"
• "flex-wrap"
• "footer-custom-item"
• "footer-custom-items"
• "footer-legal"
• "footer-links"
• "footer-social-menu"
• "footer-socials"
• "g-recaptcha-response"
• "gradient-bg"
• "grecaptcha-badge"
• "grecaptcha-error"
• "grecaptcha-logo"
• "header-logo-desktop"
• "header-logo-mobile"
• "hidden"
• "home"
• "inner-column-d"
• "instagram"
• "kadence-column_47d-32p1"
• "legal"
• "logo-medium"
• "main-nav"
• "main-nav--mobile-only"
• "menu-item"
• "menu-item-has-children"
• "menu-item-object-custom"
• "menu-item-object-page"
• "menu-item-social"
• "menu-item-type-custom"
• "menu-item-type-post_type"
• "menu-main-container"
• "menu-social-container"
• "mobile"
• "mobile-nav-header"
• "nav-btn"
• "nav-subscribe"
• "onetrust-close-btn-handler"
• "onetrust-pc-dark-filter"
• "open-slide-menu-gr"
• "ot-abt-tab"
• "ot-active-menu"
• "ot-addtl-vendors"
• "ot-always-active"
• "ot-always-active-group"
• "ot-btn-container"
• "ot-btn-subcntr"
• "ot-cat-grp"
• "ot-cat-header"
• "ot-cat-item"
• "ot-category-desc"
• "ot-chkbox"
• "ot-close-cntr"
• "ot-close-icon"
• "ot-consent-hdr"
• "ot-desc-cntr"
• "ot-enbl-chr"
• "ot-fade-in"
• "ot-fltr-btns"
• "ot-fltr-opt"
• "ot-fltr-opts"
• "ot-fltr-scrlcnt"
• "ot-grp-desc"
• "ot-grp-hdr1"
• "ot-grps-cntr"
• "ot-hide"
• "ot-hlst-cntr"
• "ot-label-status"
• "ot-label-txt"
• "ot-li-hdr"
• "ot-link-btn"
• "ot-lst-cntr"
• "ot-lst-subhdr"
• "ot-optout-icon"
• "ot-optout-signal"
• "ot-pc-footer"
• "ot-pc-footer-logo"
• "ot-pc-header"
• "ot-pc-logo"
• "ot-pc-refuse-all-handler"
• "ot-pc-scrollbar"
• "ot-scrn-rdr"
• "ot-sdk-column"
• "ot-sdk-columns"
• "ot-sdk-container"
• "ot-sdk-eight"
• "ot-sdk-four"
• "ot-sdk-row"
• "ot-sdk-show-settings"
• "ot-sel-all"
• "ot-sel-all-chkbox"
• "ot-sel-all-hdr"
• "ot-tab-desc"
• "ot-tab-list"
• "ot-text-resize"
• "ot-tgl-cntr"
• "ot-title-cntr"
• "ot-vs-config"
• "otPcTab"
• "page"
• "page-home"
• "page-id-6"
• "page-template-default"
• "pd-row-buamaí-grá-layout"
• "peekaboo"
• "privacy-notice-link"
• "promo"
• "pt-d-tinta-default"
• "pt-musas-default"
• "push-global-ccpa"
• "save-preference-btn-handler"
• "siniaki"
• "site-footer"
• "site-header"
• "slide-menu-gr-child"
• "slide-menu-gr-child-peekaboo"
• "slide-menu-gr-container"
• "slide-menu-gr-container--mobile-only"
• "slide-menu-gr-item"
• "slide-menu-gr-parent"
• "slide-menu-gr-parent--mobile-only"
• "slide-menu-gr-peakaboo--mobile-only"
• "slide-menu-toggle"
• "snapchat"
• "social-menu--mobile-only"
• "social-nav"
• "store-sub-menu"
• "sub-menu-566"
• "t-row-column-wrap"
• "talismani-column-tpd"
• "tiktok"
• "tumblr"
• "twitter"
• "umggr-evidon-cookie-choices-footer"
• "umggr-evidon-cookie-choices-footer-container"
• "umggr-mobile-view"
• "umggr-page6"
• "vorsitzende"
• "with-children"
• "wp-embed-responsive"
• "yoast-schema-graph"
• "youtube"
  `

  const sus = `
• "kadence-column_47d-32p1" ! This one looks like it could be normal i'm not sure
• "pt-d-tinta-default" tinta? (is this a font?)
• "pt-musas-default" musas?? (is this a font?)
• "siniaki"
• "talismani-column-tpd" ??? the fact that this is in the middle of the string doesn't lead me to believe its special
• "vorsitzende"
• "push-global-ccpa" ccpa is not a vault track name but funny to see California's Privacy protections over here
  `


  const classNameCode = `
  var allElements = document.querySelectorAll('*');
  new Set(Array.from(allElements.values()).flatMap(n => n.className.toString().split(/\\s+/)))`


  return (
    <div>
      <h1>So anyways, I ran this code from the console on the 321 error page</h1>

      <p style={{whiteSpace: 'pre-line'}}>{classNameCode}</p>

      <h1>And here were all of the class names...</h1>
      <p>... some of them are pretty standard e.g. "legal" or anything starting with "ot-" but "siniaki" and "vorsitzende" stand out for sure</p>


      <p style={{whiteSpace: 'pre-line'}}>{uniqClassNames}</p>
      <h2>Heres the remaining  class names with one that look normal to me taken out</h2>
      <p style={{whiteSpace: 'pre-line'}}>{sus}</p>

      <h1>Here's the raw data if that's your thing</h1>
      <p style={{whiteSpace: 'pre-line'}}>{text}
      <br/>{text2}
      </p>
      <p style={{whiteSpace: 'pre-line'}}>{text3}</p>

    </div>
  );

} export default Clowning;
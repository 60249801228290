import { entireCatalog } from "../../Catalog/catalog"
import { SongID } from "../../Catalog/types"

export type SurpriseSong = {
  songID: SongID
  city: string
  state?: string
  country?: string
  date: string // MM/DD/YYYY
  nightNumber: number
  piano?: boolean
  guitar?: boolean
  mashup?: SongID
  mashup2?: SongID
  note?: string
}

export const SurpriseSongs: SurpriseSong[] = [
  // Glendale
  {
    songID: 'mirrorball',
    city: "Glendale",
    state: "AZ",
    date: '03/17/2023',
    nightNumber: 1,
    piano: true
  },
  {
    songID: 'tim_mcgraw',
    city: "Glendale",
    state: "AZ",
    date: '03/17/2023',
    nightNumber: 1,
    guitar: true
  },
  {
    songID: 'state_of_grace',
    city: "Glendale",
    state: "AZ",
    date: '03/18/2023',
    nightNumber: 2,
    guitar: true
  },
  {
    songID: 'this_is_me_trying',
    city: "Glendale",
    state: "AZ",
    date: '03/18/2023',
    nightNumber: 2,
    guitar: true
  },

  // Las Vegas
  {
    songID: 'our_song',
    city: "Las Vegas",
    state: "NV",
    date: '03/24/2023',
    nightNumber: 1,
    piano: true
  },
  {
    songID: 'snow_on_the_beach',
    city: "Las Vegas",
    state: "NV",
    date: '03/24/2023',
    nightNumber: 1,
    guitar: true
  },
  {
    songID: 'cowboy_like_me',
    city: "Las Vegas",
    state: "NV",
    date: '03/25/2023',
    nightNumber: 2,
    note: 'with Marcus Mumford',
    piano: true
  },
  {
    songID: 'white_horse',
    city: "Las Vegas",
    state: "NV",
    date: '03/25/2023',
    nightNumber: 2,
    guitar: true
  },

  //
  {
    songID: 'sad_beautiful_tragic',
    city: "Arlington",
    state: "TX",
    date: '03/31/2023',
    nightNumber: 1,
    piano: true
  },
  {
    songID: 'ours',
    city: "Arlington",
    state: "TX",
    date: '03/31/2023',
    nightNumber: 1,
    guitar: true
  },
  {
    songID: 'death_by_a_thousand_cuts',
    city: "Arlington",
    state: "TX",
    date: '04/01/2023',
    nightNumber: 2,
    piano: true
  },
  {
    songID: 'clean',
    city: "Arlington",
    state: "TX",
    date: '04/01/2023',
    nightNumber: 2,
    guitar: true
  },
  {
    songID: 'jump_then_fall',
    city: "Arlington",
    state: "TX",
    date: '04/02/2023',
    nightNumber: 3,
    piano: true
  },
  {
    songID: 'the_lucky_one',
    city: "Arlington",
    state: "TX",
    date: '04/02/2023',
    nightNumber: 3,
    guitar: true
  },


  {
    songID: 'speak_now_song',
    city: "Tampa",
    state: "FL",
    date: '04/13/2023',
    nightNumber: 1,
    guitar: true
  },
  {
    songID: 'treacherous',
    city: "Tampa",
    state: "FL",
    date: '04/13/2023',
    nightNumber: 1,
    piano: true
  },
  {
    songID: 'the_great_war',
    city: "Tampa",
    state: "FL",
    date: '04/14/2023',
    nightNumber: 2,
    note: 'with Aaron Dessner',
    piano: true
  },
  {
    songID: 'youre_on_your_own_kid',
    city: "Tampa",
    state: "FL",
    date: '04/14/2023',
    nightNumber: 2,
    guitar: true
  },
  {
    songID: 'mad_woman',
    city: "Tampa",
    note: 'with Aaron Dessner',
    state: "FL",
    date: '04/15/2023',
    nightNumber: 3,
    piano: true
  },
  {
    songID: 'mean',
    city: "Tampa",
    state: "FL",
    date: '04/15/2023',
    nightNumber: 3,
    guitar: true
  },

  {
    songID: 'wonderland',
    city: "Houston",
    state: "TX",
    date: '04/21/2023',
    nightNumber: 1,
    piano: true
  },
  {
    songID: 'youre_not_sorry',
    city: "Houston",
    state: "TX",
    date: '04/21/2023',
    nightNumber: 1,
    guitar: true
  },
  {
    songID: 'a_place_in_this_world',
    city: "Houston",
    state: "TX",
    date: '04/22/2023',
    nightNumber: 2,
    piano: true
  },
  {
    songID: 'today_was_a_fairytale',
    city: "Houston",
    state: "TX",
    date: '04/22/2023',
    nightNumber: 2,
    guitar: true
  },
  {
    songID: 'begin_again',
    city: "Houston",
    state: "TX",
    date: '04/23/2023',
    nightNumber: 3,
    piano: true
  },
  {
    songID: 'cold_as_you',
    city: "Houston",
    state: "TX",
    date: '04/23/2023',
    nightNumber: 3,
    guitar: true
  },


  {
    songID: 'the_other_side_of_the_door',
    city: "Atlanta",
    state: "GA",
    date: '04/28/2023',
    nightNumber: 1,
    piano: true
  },
  {
    songID: 'coney_island',
    city: "Atlanta",
    state: "GA",
    date: '04/28/2023',
    nightNumber: 1,
    guitar: true
  },
  {
    songID: 'high_infidelity',
    city: "Atlanta",
    state: "GA",
    date: '04/29/2023',
    nightNumber: 2,
    piano: true
  },
  {
    songID: 'gorgeous',
    city: "Atlanta",
    state: "GA",
    date: '04/29/2023',
    nightNumber: 2,
    guitar: true
  },
  {
    songID: 'i_bet_you_think_about_me',
    city: "Atlanta",
    state: "GA",
    date: '04/30/2023',
    nightNumber: 3,
    piano: true
  },
  {
    songID: 'how_you_get_the_girl',
    city: "Atlanta",
    state: "GA",
    date: '04/30/2023',
    nightNumber: 3,
    guitar: true
  },

  {
    songID: 'sparks_fly',
    city: "Nashville",
    state: "TN",
    date: '05/05/2023',
    nightNumber: 1,
    piano: true
  },
  {
    songID: 'teardrops_on_my_guitar',
    city: "Nashville",
    state: "TN",
    date: '05/05/2023',
    nightNumber: 1,
    guitar: true
  },
  {
    songID: 'out_of_the_woods',
    city: "Nashville",
    state: "TN",
    date: '05/06/2023',
    nightNumber: 2,
    piano: true
  },
  {
    songID: 'fifteen',
    city: "Nashville",
    state: "TN",
    date: '05/06/2023',
    nightNumber: 2,
    guitar: true
  },
  {
    songID: 'wcs',
    city: "Nashville",
    state: "TN",
    note: 'with Aaron Dessner',
    date: '05/07/2023',
    nightNumber: 3,
    piano: true
  },
  {
    songID: 'mine',
    city: "Nashville",
    state: "TN",
    date: '05/07/2023',
    nightNumber: 3,
    guitar: true
  },

  {
    songID: 'gold_rush',
    city: "Philadelphia",
    state: "PA",
    date: '05/12/2023',
    nightNumber: 1,
    piano: true
  },
  {
    songID: 'come_back_be_here',
    city: "Philadelphia",
    state: "PA",
    date: '05/12/2023',
    nightNumber: 1,
    guitar: true
  },
  {
    songID: 'forever_and_always',
    city: "Philadelphia",
    state: "PA",
    date: '05/13/2023',
    nightNumber: 2,
    piano: true
  },
  {
    songID: 'this_love',
    city: "Philadelphia",
    state: "PA",
    date: '05/13/2023',
    nightNumber: 2,
    guitar: true
  },
  {
    songID: 'hey_stephen',
    city: "Philadelphia",
    state: "PA",
    date: '05/14/2023',
    nightNumber: 3,
    piano: true
  },
  {
    songID: 'the_best_day',
    city: "Philadelphia",
    state: "PA",
    date: '05/14/2023',
    nightNumber: 3,
    guitar: true
  },

  {
    songID: 'shouldve_said_no',
    city: 'Foxborough',
    state: ' MA',
    date: '05/19/2023',
    nightNumber: 1,
    guitar: true
  },
  {
    songID: 'better_man',
    city: 'Foxborough',
    state: ' MA',
    date: '05/19/2023',
    nightNumber: 1,
    piano: true
  },
  {
    songID: 'question',
    city: 'Foxborough',
    state: ' MA',
    date: '05/20/2023',
    nightNumber: 2,
    guitar: true
  },
  {
    songID: 'invisible',
    city: 'Foxborough',
    state: 'MA',
    date: '05/20/2023',
    nightNumber: 2,
    piano: true
  },
  {
    songID: 'i_think_he_knows',
    city: 'Foxborough',
    state: 'MA',
    date: '05/21/2023',
    nightNumber: 3,
    guitar: true
  },
  {
    songID: 'red_song',
    city: 'Foxborough',
    state: 'MA',
    date: '05/21/2023',
    nightNumber: 3,
    guitar: true
  },

  {
    songID: 'getaway_car',
    city: 'East Rutherford',
    state: 'NJ',
    date: '05/26/2023',
    nightNumber: 1,
    guitar: true,
    note: 'with Jack Antonoff',
  },
  {
    songID: 'maroon',
    city: 'East Rutherford',
    state: 'NJ',
    date: '05/26/2023',
    nightNumber: 1,
    piano: true
  },
  {
    songID: 'holy_ground',
    city: 'East Rutherford',
    state: 'NJ',
    date: '05/27/2023',
    nightNumber: 2,
    guitar: true
  },
  {
    songID: 'false_god',
    city: 'East Rutherford',
    state: 'NJ',
    date: '05/27/2023',
    nightNumber: 2,
    piano: true
  },
  {
    songID: 'welcome_to_new_york',
    city: 'East Rutherford',
    state: 'NJ',
    date: '05/28/2023',
    nightNumber: 3,
    guitar: true
  },
  {
    songID: 'clean',
    city: 'East Rutherford',
    state: 'NJ',
    date: '05/28/2023',
    nightNumber: 3,
    piano: true
  },

  {
    songID: 'i_wish_you_would',
    city: 'Chicago',
    state: 'IL',
    date: '06/02/2023',
    nightNumber: 1,
    guitar: true
  },
  {
    songID: 'the_lakes',
    city: 'Chicago',
    state: 'IL',
    date: '06/02/2023',
    nightNumber: 1,
    piano: true
  },
  {
    songID: 'you_all_over_me',
    city: 'Chicago',
    state: 'IL',
    date: '06/03/2023',
    nightNumber: 2,
    note: 'with Maren Morris',
    guitar: true
  },
  {
    songID: 'i_dont_wanna_live_forever',
    city: 'Chicago',
    state: 'IL',
    date: '06/03/2023',
    nightNumber: 2,
    piano: true
  },
  {
    songID: 'hits_different',
    city: 'Chicago',
    state: 'IL',
    date: '06/04/2023',
    nightNumber: 3,
    guitar: true
  },
  {
    songID: 'the_moment_i_knew',
    city: 'Chicago',
    state: 'IL',
    date: '06/04/2023',
    nightNumber: 3,
    piano: true
  },

  {
    songID: 'haunted',
    city: 'Detroit',
    state: 'MI',
    date: '06/09/2023',
    nightNumber: 1,
    guitar: true
  },
  {
    songID: 'i_almost_do',
    city: 'Detroit',
    state: 'MI',
    date: '06/09/2023',
    nightNumber: 1,
    piano: true
  },
  {
    songID: 'all_you_had_to_do_was_stay',
    city: 'Detroit',
    state: 'MI',
    date: '06/10/2023',
    nightNumber: 2,
    guitar: true
  },
  {
    songID: 'breathe',
    city: 'Detroit',
    state: 'MI',
    date: '06/10/2023',
    nightNumber: 2,
    piano: true
  },

  {
    songID: 'mr_perfectly_fine',
    city: 'Pittsburgh',
    state: 'PA',
    date: '06/16/2023',
    nightNumber: 1,
    guitar: true
  },
  {
    songID: 'the_last_time',
    city: 'Pittsburgh',
    state: 'PA',
    date: '06/16/2023',
    nightNumber: 1,
    piano: true
  },
  {
    songID: 'seven',
    city: 'Pittsburgh',
    state: 'PA',
    date: '06/17/2023',
    nightNumber: 2,
    note: 'with Aaron Dessner',
    guitar: true
  },
  {
    songID: 'the_story_of_us',
    city: 'Pittsburgh',
    state: 'PA',
    date: '06/17/2023',
    nightNumber: 2,
    piano: true
  },

  {
    songID: 'paper_rings',
    city: 'Minneapolis',
    state: 'MN',
    date: '06/23/2023',
    nightNumber: 1,
    guitar: true
  },
  {
    songID: 'if_this_was_a_movie',
    city: 'Minneapolis',
    state: 'MN',
    date: '06/23/2023',
    nightNumber: 1,
    piano: true
  },
  {
    songID: 'dear_john',
    city: 'Minneapolis',
    state: 'MN',
    date: '06/24/2023',
    nightNumber: 2,
    guitar: true
  },
  {
    songID: 'daylight',
    city: 'Minneapolis',
    state: 'MN',
    date: '06/24/2023',
    nightNumber: 2,
    piano: true
  },

  {
    songID: 'im_only_me_when_im_with_you',
    city: 'Cincinnati',
    state: 'OH',
    date: '06/30/2023',
    nightNumber: 1,
    guitar: true
  },
  {
    songID: 'evermore_song',
    city: 'Cincinnati',
    state: 'OH',
    date: '06/30/2023',
    nightNumber: 1,
    piano: true
  },
  {
    songID: 'ivy',
    note: 'with Aaron Dessner',
    city: 'Cincinnati',
    state: 'OH',
    date: '07/01/2023',
    nightNumber: 2,
    guitar: true
  },
  {
    songID: 'call_it_what_you_want',
    city: 'Cincinnati',
    state: 'OH',
    date: '07/01/2023',
    nightNumber: 2,
    piano: true
  },

  {
    songID: 'never_grow_up',
    city: 'Kansas City',
    state: 'MO',
    date: '07/07/2023',
    nightNumber: 1,
    guitar: true
  },
  {
    songID: 'when_emma_falls_in_love',
    city: 'Kansas City',
    state: 'MO',
    date: '07/07/2023',
    nightNumber: 1,
    piano: true
  },
  {
    songID: 'last_kiss',
    city: 'Kansas City',
    state: 'MO',
    date: '07/08/2023',
    nightNumber: 2,
    guitar: true
  },
  {
    songID: 'dorothea',
    city: 'Kansas City',
    state: 'MO',
    date: '07/08/2023',
    nightNumber: 2,
    piano: true
  },
  
  {
    songID: 'picture_to_burn',
    city: 'Denver',
    state: 'CO',
    date: '07/14/2023',
    nightNumber: 1,
    guitar: true
  },
  {
    songID: 'timeless',
    city: 'Denver',
    state: 'CO',
    date: '07/14/2023',
    nightNumber: 1,
    piano: true
  },
  {
    songID: 'starlight',
    city: 'Denver',
    state: 'CO',
    date: '07/15/2023',
    nightNumber: 2,
    guitar: true
  },
  {
    songID: 'back_to_december',
    city: 'Denver',
    state: 'CO',
    date: '07/15/2023',
    nightNumber: 2,
    piano: true
  },
  {
    songID: 'this_is_why_we_cant_have_nice_things',
    city: 'Seattle',
    state: 'WA',
    date: '07/22/2023',
    nightNumber: 1,
    guitar: true
  },
  {
    songID: 'everything_has_changed',
    city: 'Seattle',
    state: 'WA',
    date: '07/22/2023',
    nightNumber: 1,
    piano: true
  },
  {
    songID: 'message_in_a_bottle',
    city: 'Seattle',
    state: 'WA',
    date: '07/23/2023',
    nightNumber: 2,
    guitar: true
  },
  {
    songID: 'tied_together_with_a_smile',
    city: 'Seattle',
    state: 'WA',
    date: '07/23/2023',
    nightNumber: 2,
    piano: true
  },
  {
    songID: 'right_where_you_left_me',
    city: 'Santa Clara',
    state: 'CA',
    note: 'with Aaron Dessner',
    date: '07/28/2023',
    nightNumber: 1,
    guitar: true
  },
  {
    songID: 'castles_crumbling',
    city: 'Santa Clara',
    state: 'CA',
    date: '07/28/2023',
    nightNumber: 1,
    piano: true
  },
  {
    songID: 'stay_stay_stay',
    city: 'Santa Clara',
    state: 'CA',
    date: '07/29/2023',
    nightNumber: 2,
    guitar: true
  },
  {
    songID: 'all_of_the_girls_youve_loved_before',
    city: 'Santa Clara',
    state: 'CA',
    date: '07/29/2023',
    nightNumber: 2,
    piano: true
  },
  {
    songID: 'i_can_see_you',
    city: 'Los Angeles',
    state: 'CA',
    date: '08/03/2023',
    nightNumber: 1,
    guitar: true
  },
  {
    songID: 'maroon',
    city: 'Los Angeles',
    state: 'CA',
    date: '08/03/2023',
    nightNumber: 1,
    piano: true
  },
  {
    songID: 'our_song',
    city: 'Los Angeles',
    state: 'CA',
    date: '08/04/2023',
    nightNumber: 2,
    guitar: true
  },
  {
    songID: 'you_are_in_love',
    city: 'Los Angeles',
    state: 'CA',
    date: '08/04/2023',
    nightNumber: 2,
    piano: true
  },
  {
    songID: 'death_by_a_thousand_cuts',
    city: 'Los Angeles',
    state: 'CA',
    date: '08/05/2023',
    nightNumber: 3,
    guitar: true
  },
  {
    songID: 'youre_on_your_own_kid',
    city: 'Los Angeles',
    state: 'CA',
    date: '08/05/2023',
    nightNumber: 3,
    piano: true
  },
  {
    songID: 'dress',
    city: 'Los Angeles',
    state: 'CA',
    date: '08/07/2023',
    nightNumber: 4,
    guitar: true
  },
  {
    songID: 'exile',
    city: 'Los Angeles',
    state: 'CA',
    date: '08/07/2023',
    nightNumber: 4,
    piano: true
  },
  {
    songID: 'i_know_places',
    city: 'Los Angeles',
    state: 'CA',
    date: '08/08/2023',
    nightNumber: 5,
    guitar: true
  },
  {
    songID: 'king_of_my_heart',
    city: 'Los Angeles',
    state: 'CA',
    date: '08/08/2023',
    nightNumber: 5,
    piano: true
  },
  {
    songID: 'new_romantics',
    city: 'Los Angeles',
    state: 'CA',
    date: '08/09/2023',
    nightNumber: 6,
    guitar: true
  },
  {
    songID: 'new_years_day',
    city: 'Los Angeles',
    state: 'CA',
    date: '08/09/2023',
    nightNumber: 6,
    piano: true
  },
  {
    songID: 'i_forgot_that_you_existed',
    city: 'Mexico City',
    state: 'Mexico',
    date: '08/24/2023',
    nightNumber: 1,
    guitar: true
  },
  {
    songID: 'sweet_nothing',
    city: 'Mexico City',
    state: 'Mexico',
    date: '08/24/2023',
    nightNumber: 1,
    piano: true
  },
  {
    songID: 'tell_me_why',
    city: 'Mexico City',
    state: 'Mexico',
    date: '08/25/2023',
    nightNumber: 2,
    guitar: true
  },
  {
    songID: 'snow_on_the_beach',
    city: 'Mexico City',
    state: 'Mexico',
    date: '08/25/2023',
    nightNumber: 2,
    piano: true
  },
  {
    songID: 'cornelia_street',
    city: 'Mexico City',
    state: 'Mexico',
    date: '08/26/2023',
    nightNumber: 3,
    guitar: true
  },
  {
    songID: 'youre_on_your_own_kid',
    city: 'Mexico City',
    state: 'Mexico',
    date: '08/26/2023',
    nightNumber: 3,
    piano: true
  },
  {
    songID: 'afterglow',
    city: 'Mexico City',
    state: 'Mexico',
    date: '08/27/2023',
    nightNumber: 4,
    guitar: true
  },
  {
    songID: 'maroon',
    city: 'Mexico City',
    state: 'Mexico',
    date: '08/27/2023',
    nightNumber: 4,
    piano: true
  },

  {
    songID: 'the_very_first_night',
    city: 'Buenos Aires',
    state: 'Argentina',
    date: '11/09/2023',
    nightNumber: 1,
    guitar: true
  },
  {
    songID: 'labyrinth',
    city: 'Buenos Aires',
    state: 'Argentina',
    date: '11/09/2023',
    nightNumber: 1,
    piano: true
  },
  {
    songID: 'is_it_over_now',
    mashup: 'out_of_the_woods',
    city: 'Buenos Aires',
    state: 'Argentina',
    date: '11/11/2023',
    nightNumber: 2,
    guitar: true
  },
  {
    songID: 'end_game',
    city: 'Buenos Aires',
    state: 'Argentina',
    date: '11/11/2023',
    nightNumber: 2,
    piano: true
  },
  {
    songID: 'better_than_revenge',
    city: 'Buenos Aires',
    state: 'Argentina',
    date: '11/12/2023',
    nightNumber: 3,
    guitar: true
  },
  {
    songID: 'slut',
    city: 'Buenos Aires',
    state: 'Argentina',
    date: '11/12/2023',
    nightNumber: 3,
    piano: true
  },

  {
    songID: 'stay_beautiful',
    city: 'Rio De Janeiro',
    state: 'Brazil',
    date: '11/17/2023',
    nightNumber: 1,
    guitar: true
  },
  {
    songID: 'suburban_legends',
    city: 'Rio De Janeiro',
    state: 'Brazil',
    date: '11/17/2023',
    nightNumber: 1,
    piano: true
  },
  {
    songID: 'dancing_with_our_hands_tied',
    city: 'Rio De Janeiro',
    state: 'Brazil',
    date: '11/19/2023',
    nightNumber: 2,
    guitar: true
  },
  {
    songID: 'bigger_than_the_whole_sky',
    city: 'Rio De Janeiro',
    state: 'Brazil',
    date: '11/19/2023',
    nightNumber: 2,
    piano: true
  },
  {
    songID: 'me',
    city: 'Rio De Janeiro',
    state: 'Brazil',
    date: '11/20/2023',
    nightNumber: 3,
    guitar: true
  },
  {
    songID: 'so_it_goes',
    city: 'Rio De Janeiro',
    state: 'Brazil',
    date: '11/20/2023',
    nightNumber: 3,
    piano: true
  },
  {
    songID: 'now_that_we_dont_talk',
    city: 'São Paulo',
    state: 'Brazil',
    date: '11/24/2023',
    nightNumber: 1,
    guitar: true
  },
  {
    songID: 'innocent',
    city: 'São Paulo',
    state: 'Brazil',
    date: '11/24/2023',
    nightNumber: 1,
    piano: true
  },
  {
    songID: 'safe_and_sound',
    city: 'São Paulo',
    state: 'Brazil',
    date: '11/25/2023',
    nightNumber: 2,
    guitar: true
  },
  {
    songID: 'untouchable',
    city: 'São Paulo',
    state: 'Brazil',
    date: '11/25/2023',
    nightNumber: 2,
    piano: true
  },
  {
    songID: 'say_dont_go',
    city: 'São Paulo',
    state: 'Brazil',
    date: '11/26/2023',
    nightNumber: 3,
    guitar: true
  },
  {
    songID: 'its_time_to_go',
    city: 'São Paulo',
    state: 'Brazil',
    date: '11/26/2023',
    nightNumber: 3,
    piano: true
  },

  // Tokyo
  {
    songID: 'dear_reader',
    city: 'Tokyo',
    state: 'Japan',
    date: '02/07/2024',
    nightNumber: 1,
    guitar: true
  },
  {
    songID: 'holy_ground',
    city: 'Tokyo',
    state: 'Japan',
    date: '02/07/2024',
    nightNumber: 1,
    piano: true
  },
  {
    songID: 'eyes_open',
    city: 'Tokyo',
    state: 'Japan',
    date: '02/08/2024',
    nightNumber: 2,
    guitar: true
  },
  {
    songID: 'electric_touch',
    city: 'Tokyo',
    state: 'Japan',
    date: '02/08/2024',
    nightNumber: 2,
    piano: true
  },
  {
    songID: 'superman',
    city: 'Tokyo',
    state: 'Japan',
    date: '02/09/2024',
    nightNumber: 3,
    guitar: true
  },
  {
    songID: 'the_outside',
    city: 'Tokyo',
    state: 'Japan',
    date: '02/09/2024',
    nightNumber: 3,
    piano: true
  },
  {
    songID: 'come_in_with_the_rain',
    city: 'Tokyo',
    state: 'Japan',
    date: '02/10/2024',
    nightNumber: 4,
    guitar: true
  },
  {
    songID: 'youre_on_your_own_kid',
    city: 'Tokyo',
    state: 'Japan',
    date: '02/10/2024',
    nightNumber: 4,
    piano: true
  },

  // Melbourne
  {
    songID: 'red_song',
    city: 'Melbourne',
    state: 'Australia',
    date: '02/16/2024',
    nightNumber: 1,
    guitar: true
  },
  {
    songID: 'youre_losing_me',
    city: 'Melbourne',
    state: 'Australia',
    date: '02/16/2024',
    nightNumber: 1,
    piano: true
  },
  {
    songID: 'getaway_car',
    mashup: 'august',
    mashup2: 'the_other_side_of_the_door',
    city: 'Melbourne',
    state: 'Australia',
    date: '02/17/2024',
    nightNumber: 2,
    guitar: true
  },
  {
    songID: 'this_is_me_trying',
    city: 'Melbourne',
    state: 'Australia',
    date: '02/17/2024',
    nightNumber: 2,
    piano: true
  },
  {
    songID: 'come_back_be_here',
    mashup: 'daylight',
    city: 'Melbourne',
    state: 'Australia',
    date: '02/18/2024',
    nightNumber: 3,
    guitar: true
  },
  {
    songID: 'teardrops_on_my_guitar',
    city: 'Melbourne',
    state: 'Australia',
    date: '02/18/2024',
    nightNumber: 3,
    piano: true
  },

  // SYDNEY!
  {
    songID: 'how_you_get_the_girl',
    city: 'Sydney',
    state: 'Australia',
    date: '02/23/2024',
    nightNumber: 1,
    guitar: true
  },
  {
    songID: 'white_horse',
    mashup: 'coney_island',
    city: 'Sydney',
    state: 'Australia',
    date: '02/23/2024',
    nightNumber: 1,
    note: 'with Sabrina Carpentener',
    piano: true
  },
  {
    songID: 'shouldve_said_no',
    city: 'Sydney',
    state: 'Australia',
    mashup: 'youre_not_sorry',
    date: '02/24/2024',
    nightNumber: 2,
    guitar: true
  },
  {
    songID: 'new_years_day',
    city: 'Sydney',
    mashup: 'peace',
    state: 'Australia',
    date: '02/24/2024',
    nightNumber: 2,
    piano: true
  },
  {
    songID: 'haunted',
    city: 'Sydney',
    state: 'Australia',
    mashup: 'exile',
    date: '02/25/2024',
    nightNumber: 3,
    guitar: true
  },
  {
    songID: 'is_it_over_now',
    city: 'Sydney',
    mashup: 'i_wish_you_would',
    state: 'Australia',
    date: '02/25/2024',
    nightNumber: 3,
    piano: true
  },
  {
    songID: 'forever_and_always',
    mashup: 'maroon',
    city: 'Sydney',
    state: 'Australia',
    date: '02/26/2024',
    nightNumber: 4,
    piano: true
  },
  {
    songID: 'wcs',
    city: 'Sydney',
    mashup: 'ivy',
    state: 'Australia',
    date: '02/26/2024',
    nightNumber: 4,
    guitar: true
  },

  // Singapore, 
  {
    songID: 'i_dont_wanna_live_forever',
    city: 'Singapore',
    state: '',
    nightNumber: 1,
    date: '03/02/2024',
    mashup: 'dress',
    piano: true,
  },
  {
    songID: 'mine',
    city: 'Singapore',
    state: '',
    nightNumber: 1,
    date: '03/02/2024',
    mashup: 'starlight',
    guitar: true,
  },
  {
    songID: 'long_story_short',
    city: 'Singapore',
    state: '',
    nightNumber: 2,
    date: '03/03/2024',
    mashup: 'the_story_of_us',
    piano: true,
  },
  {
    songID: 'clean',
    city: 'Singapore',
    state: '',
    nightNumber: 2,
    date: '03/03/2024',
    mashup: 'evermore_song',
    guitar: true,
  },
  {
    songID: 'this_love',
    city: 'Singapore',
    state: '',
    nightNumber: 3,
    date: '03/04/2024',
    mashup: 'call_it_what_you_want',
    piano: true,
  },
  {
    songID: 'foolish_one',
    city: 'Singapore',
    state: '',
    nightNumber: 3,
    date: '03/04/2024',
    mashup: 'tell_me_why',
    guitar: true,
  },
  {
    songID: 'death_by_a_thousand_cuts',
    city: 'Singapore',
    state: '',
    nightNumber: 4,
    date: '03/07/2024',
    mashup: 'babe',
    piano: true,
  },
  {
    songID: 'fifteen',
    city: 'Singapore',
    state: '',
    nightNumber: 4,
    date: '03/07/2024',
    mashup: 'youre_on_your_own_kid',
    guitar: true,
  },
  {
    songID: 'sparks_fly',
    city: 'Singapore',
    state: '',
    nightNumber: 5,
    date: '03/08/2024',
    mashup: 'gold_rush',
    piano: true,
  },
  {
    songID: 'false_god',
    city: 'Singapore',
    state: '',
    nightNumber: 5,
    date: '03/08/2024',
    mashup: 'slut',
    guitar: true,
  },
  {
    songID: 'tim_mcgraw',
    city: 'Singapore',
    state: '',
    nightNumber: 6,
    date: '03/09/2024',
    mashup: 'cowboy_like_me',
    piano: true,
  },
  {
    songID: 'mirrorball',
    city: 'Singapore',
    state: '',
    nightNumber: 6,
    date: '03/09/2024',
    mashup: 'epiphany',
    guitar: true,
  },


  // Paris
  {
    songID: 'paris',
    city: 'Paris',
    state: 'France',
    nightNumber: 1,
    date: '05/09/2024',
    guitar: true,
  },
  {
    songID: 'loml',
    city: 'Paris',
    state: 'France',
    nightNumber: 1,
    date: '05/09/2024',
    piano: true,
  },
  {
    songID: 'is_it_over_now',
    city: 'Paris',
    state: 'France',
    nightNumber: 2,
    date: '05/10/2024',
    mashup: 'out_of_the_woods',
    guitar: true,
  },
  {
    songID: 'my_boy_only_breaks_his_favorite_toys',
    city: 'Paris',
    state: 'France',
    nightNumber: 2,
    date: '05/10/2024',
    piano: true,
  },
  {
    songID: 'hey_stephen',
    city: 'Paris',
    state: 'France',
    nightNumber: 3,
    date: '05/11/2024',
    guitar: true,
  },
  {
    songID: 'maroon',
    city: 'Paris',
    state: 'France',
    nightNumber: 3,
    date: '05/11/2024',
    piano: true,
  },
  {
    songID: 'the_alchemy',
    city: 'Paris',
    state: 'France',
    nightNumber: 4,
    date: '05/12/2024',
    mashup: 'treacherous',
    guitar: true,
  },
  {
    songID: 'begin_again',
    city: 'Paris',
    state: 'France',
    nightNumber: 4,
    date: '05/12/2024',
    mashup: 'paris',
    piano: true,
  },

  // Stockholm
  {
    songID: 'i_think_he_knows',
    city: 'Stockholm',
    state: 'Sweeden',
    nightNumber: 1,
    mashup: 'gorgeous',
    date: '05/17/2024',
    guitar: true,
  },
  {
    songID: 'peter',
    city: 'Stockholm',
    state: 'Sweeden',
    nightNumber: 1,
    date: '05/17/2024',
    piano: true,
  },
  {
    songID: 'guilty_as_sin',
    city: 'Stockholm',
    state: 'Sweeden',
    nightNumber: 2,
    date: '05/18/2024',
    guitar: true,
  },
  {
    songID: 'say_dont_go',
    city: 'Stockholm',
    state: 'Sweeden',
    nightNumber: 2,
    date: '05/18/2024',
    mashup: 'welcome_to_new_york',
    mashup2: 'clean',
    piano: true,
  },
  {
    songID: 'message_in_a_bottle',
    city: 'Stockholm',
    state: 'Sweeden',
    nightNumber: 3,
    date: '05/19/2024',
    guitar: true,
    mashup: "how_you_get_the_girl",
    mashup2: 'new_romantics',
  },
  {
    songID: 'how_did_it_end',
    city: 'Stockholm',
    state: 'Sweeden',
    nightNumber: 3,
    date: '05/19/2024',
    piano: true,
  },
  

  // Lisbon
  {
    songID: 'come_back_be_here',
    city: 'Lisbon',
    state: 'Portugal',
    nightNumber: 1,
    mashup: "the_way_i_loved_you",
    mashup2: "the_other_side_of_the_door",
    date: '05/24/2024',
    guitar: true,
  },
  {
    songID: 'fresh_out_the_slammer',
    city: 'Lisbon',
    state: 'Portugal',
    nightNumber: 1,
    mashup: 'high_infidelity',
    date: '05/24/2024',
    piano: true,
  },
  {
    songID: 'ttpd_song',
    city: 'Lisbon',
    state: 'Portugal',
    nightNumber: 2,
    mashup: "the_way_i_loved_you",
    mashup2: "now_that_we_dont_talk",
    date: '05/25/2024',
    guitar: true,
  },
  {
    songID: 'youre_on_your_own_kid',
    city: 'Lisbon',
    state: 'Portugal',
    nightNumber: 2,
    mashup: 'long_live',
    date: '05/25/2024',
    piano: true,
  },

  // Madrid
  {
    songID: 'sparks_fly',
    city: 'Madrid',
    state: 'Spain',
    nightNumber: 1,
    mashup: "i_can_fix_him",
    date: '05/29/2024',
    guitar: true,
  },
  {
    songID: 'i_look_in_peoples_windows',
    city: 'Madrid',
    state: 'Spain',
    nightNumber: 1,
    mashup: 'snow_on_the_beach',
    date: '05/29/2024',
    piano: true,
  },
  {
    songID: 'our_song',
    city: 'Madrid',
    state: 'Spain',
    nightNumber: 2,
    mashup: "jump_then_fall",
    date: '05/30/2024',
    guitar: true,
  },
  {
    songID: 'king_of_my_heart',
    city: 'Madrid',
    state: 'Spain',
    nightNumber: 2,
    date: '05/30/2024',
    piano: true,
  },

  // Lyon
  {
    songID: 'the_prophecy',
    city: 'Lyon',
    state: 'France',
    nightNumber: 1,
    mashup: "long_story_short",
    date: '06/02/2024',
    guitar: true,
  },
  {
    songID: 'fifteen',
    city: 'Lyon',
    state: 'France',
    nightNumber: 1,
    mashup: 'youre_on_your_own_kid',
    date: '06/02/2024',
    piano: true,
  },
  {
    songID: 'glitch',
    city: 'Lyon',
    state: 'France',
    nightNumber: 2,
    mashup: "everything_has_changed",
    date: '06/03/2024',
    guitar: true,
  },
  {
    songID: 'chole_or_sam_or_sophia_or_marcus',
    city: 'Lyon',
    state: 'France',
    nightNumber: 2,
    date: '06/03/2024',
    piano: true,
  },

  // Edinburgh
  {
    songID: 'wcs',
    city: 'Edinburgh',
    state: 'Scotland',
    nightNumber: 1,
    mashup: "i_know_places",
    date: '06/07/2024',
    guitar: true,
  },
  {
    songID: 'tis_the_damn_season',
    city: 'Edinburgh',
    state: 'Scotland',
    nightNumber: 1,
    mashup: 'daylight',
    date: '06/07/2024',
    piano: true,
  },
  {
    songID: 'the_bolter',
    city: 'Edinburgh',
    state: 'Scotland',
    nightNumber: 2,
    mashup: "getaway_car",
    date: '06/08/2024',
    guitar: true,
  },
  {
    songID: 'all_of_the_girls_youve_loved_before',
    city: 'Edinburgh',
    state: 'Scotland',
    nightNumber: 2,
    date: '06/08/2024',
    mashup: 'crazier',
    piano: true,
  },
  {
    songID: 'its_nice_to_have_a_friend',
    city: 'Edinburgh',
    state: 'Scotland',
    nightNumber: 3,
    mashup: "dorothea",
    date: '06/09/2024',
    guitar: true,
  },
  {
    songID: 'haunted',
    city: 'Edinburgh',
    state: 'Scotland',
    nightNumber: 3,
    date: '06/09/2024',
    mashup: 'exile',
    piano: true,
  },

   // Liverpool
   {
    songID: 'i_can_see_you',
    city: 'Liverpool',
    state: 'England',
    nightNumber: 1,
    mashup: "mine",
    date: '06/13/2024',
    guitar: true,
  },
  {
    songID: 'cornelia_street',
    city: 'Liverpool',
    state: 'England',
    nightNumber: 1,
    mashup: 'maroon',
    date: '06/13/2024',
    piano: true,
  },
  {
    songID: 'this_is_what_you_came_for',
    city: 'Liverpool',
    state: 'England',
    nightNumber: 2,
    mashup: "gold_rush",
    date: '06/14/2024',
    guitar: true,
  },
  {
    songID: 'the_great_war',
    city: 'Liverpool',
    state: 'England',
    nightNumber: 2,
    date: '06/14/2024',
    mashup: 'youre_losing_me',
    piano: true,
  },
  {
    songID: 'carolina',
    city: 'Liverpool',
    state: 'England',
    nightNumber: 3,
    mashup: "no_body_no_crime",
    date: '06/15/2024',
    guitar: true,
  },
  {
    songID: 'the_manuscript',
    city: 'Liverpool',
    state: 'England',
    nightNumber: 3,
    date: '06/15/2024',
    mashup: 'red_song',
    piano: true,
  },

  // Cardiff
  {
    songID: 'i_forgot_that_you_existed',
    city: 'Cardiff',
    state: 'Wales',
    nightNumber: 1,
    mashup: "this_is_why_we_cant_have_nice_things",
    date: '06/18/2024',
    guitar: true,
  },
  {
    songID: 'i_hate_it_here',
    city: 'Cardiff',
    state: 'Wales',
    nightNumber: 1,
    mashup: 'the_lakes',
    date: '06/18/2024',
    piano: true,
  },


   // London
   {
    songID: 'hits_different',
    city: 'London',
    state: 'England',
    nightNumber: 1,
    mashup: "death_by_a_thousand_cuts",
    date: '06/21/2024',
    guitar: true,
  },
  {
    songID: 'the_black_dog',
    city: 'London',
    state: 'England',
    nightNumber: 1,
    mashup: 'come_back_be_here',
    mashup2: 'maroon',
    date: '06/21/2024',
    piano: true,
  },
  {
    songID: 'thank_you_aimee',
    city: 'London',
    state: 'England',
    nightNumber: 2,
    mashup: "mean",
    date: '06/22/2024',
    guitar: true,
  },
  {
    songID: 'castles_crumbling',
    city: 'London',
    state: 'England',
    note: 'with Hayley Williams',
    nightNumber: 2,
    date: '06/22/2024',
    piano: true,
  },
  {
    songID: 'us_gracie',
    city: 'London',
    note: 'with Gracie Abrams',
    state: 'England',
    nightNumber: 3,
    date: '06/23/2024',
    guitar: true,
  },
  {
    songID: 'out_of_the_woods',
    city: 'London',
    state: 'England',
    nightNumber: 3,
    date: '06/23/2024',
    mashup: 'is_it_over_now',
    mashup2: 'clean',
    piano: true,
  },


  // Dublin
  {
    songID: 'state_of_grace',
    city: 'Dublin',
    state: 'Ireland',
    nightNumber: 1,
    mashup: "youre_on_your_own_kid",
    date: '06/28/2024',
    guitar: true,
  },
  {
    songID: 'sweet_nothing',
    city: 'Dublin',
    state: 'Ireland',
    nightNumber: 1,
    mashup: 'hoax',
    date: '06/28/2024',
    piano: true,
  },
  {
    songID: 'the_albatross',
    city: 'Dublin',
    state: 'Ireland',
    nightNumber: 2,
    mashup: "dancing_with_our_hands_tied",
    date: '06/29/2024',
    guitar: true,
  },
  {
    songID: 'this_love',
    city: 'Dublin',
    state: 'Ireland',
    nightNumber: 2,
    mashup: 'ours',
    date: '06/29/2024',
    piano: true,
  },
  {
    songID: 'clara_bow',
    city: 'Dublin',
    state: 'Ireland',
    mashup: 'the_lucky_one',
    nightNumber: 3,
    date: '06/30/2024',
    guitar: true,
  },
  {
    songID: 'youre_on_your_own_kid',
    city: 'Dublin',
    state: 'Ireland',
    nightNumber: 3,
    date: '06/30/2024',
    piano: true,
  },

  // Amsterdam
  {
    songID: 'guilty_as_sin',
    mashup: "untouchable",
    city: 'Amsterdam',
    state: 'Netherland',
    nightNumber: 1,
    date: '07/04/2024',
    guitar: true,
  },
  {
    songID: 'the_archer',
    mashup: 'question',
    city: 'Amsterdam',
    state: 'Netherland',
    nightNumber: 1,
    date: '07/04/2024',
    piano: true,
  },
  {
    songID: 'imgonnagetyouback',
    mashup: 'dress',
    city: 'Amsterdam',
    state: 'Netherland',
    nightNumber: 2,
    date: '07/05/2024',
    guitar: true,
  },
  {
    songID: 'you_are_in_love',
    mashup: 'cowboy_like_me',
    city: 'Amsterdam',
    state: 'Netherland',
    nightNumber: 2,
    date: '07/05/2024',
    piano: true,
  },
  {
    songID: 'sweeter_than_fiction',
    mashup: 'holy_ground',
    city: 'Amsterdam',
    state: 'Netherland',
    nightNumber: 3,
    date: '07/06/2024',
    guitar: true,
  },
  {
    songID: 'marys_song',
    mashup: 'so_high_school',
    mashup2: 'everything_has_changed',
    city: 'Amsterdam',
    state: 'Netherland',
    nightNumber: 3,
    date: '07/06/2024',
    guitar: true,
  },

  // Zurich
  {
    songID: 'right_where_you_left_me',
    mashup: "all_you_had_to_do_was_stay",
    city: 'Zurich',
    state: 'Switzerland',
    nightNumber: 1,
    date: '07/09/2024',
    guitar: true,
  },
  {
    songID: 'last_kiss',
    mashup: 'sad_beautiful_tragic',
    city: 'Zurich',
    state: 'Switzerland',
    nightNumber: 1,
    date: '07/09/2024',
    piano: true,
  },
  {
    songID: 'closure',
    mashup: 'a_perfectly_good_heart',
    city: 'Zurich',
    state: 'Switzerland',
    nightNumber: 2,
    date: '07/10/2024',
    guitar: true,
  },
  {
    songID: 'robin',
    mashup: 'never_grow_up',
    city: 'Zurich',
    state: 'Switzerland',
    nightNumber: 2,
    date: '07/10/2024',
    piano: true,
  },

  // Milan
  {
    songID: 'the_one',
    mashup: "wonderland",
    city: 'Milan',
    state: 'Italy',
    nightNumber: 1,
    date: '07/13/2024',
    guitar: true,
  },
  {
    songID: 'i_almost_do',
    mashup: 'the_moment_i_knew',
    city: 'Milan',
    state: 'Italy',
    nightNumber: 1,
    date: '07/13/2024',
    piano: true,
  },
  {
    songID: 'mr_perfectly_fine',
    mashup: 'red_song',
    city: 'Milan',
    state: 'Italy',
    nightNumber: 2,
    date: '07/14/2024',
    guitar: true,
  },
  {
    songID: 'getaway_car',
    mashup: 'out_of_the_woods',
    city: 'Milan',
    state: 'Italy',
    nightNumber: 2,
    date: '07/14/2024',
    piano: true,
  },

   // Gelsenkirchen
   {
    songID: 'superstar',
    mashup: "invisible_string",
    city: 'Gelsenkirchen',
    state: 'Germany',
    nightNumber: 1,
    date: '07/17/2024',
    guitar: true,
  },
  {
    songID: 'slut',
    mashup: 'false_god',
    city: 'Gelsenkirchen',
    state: 'Germany',
    nightNumber: 1,
    date: '07/17/2024',
    piano: true,
  },
  {
    songID: 'speak_now_song',
    mashup: 'hey_stephen',
    city: 'Gelsenkirchen',
    state: 'Germany',
    nightNumber: 2,
    date: '07/18/2024',
    guitar: true,
  },
  {
    songID: 'this_is_me_trying',
    mashup: 'labyrinth',
    city: 'Gelsenkirchen',
    state: 'Germany',
    nightNumber: 2,
    date: '07/18/2024',
    piano: true,
  },
  {
    songID: 'paper_rings',
    mashup: 'stay_stay_stay',
    city: 'Gelsenkirchen',
    state: 'Germany',
    nightNumber: 3,
    date: '07/19/2024',
    guitar: true,
  },
  {
    songID: 'its_time_to_go',
    mashup: 'better_man',
    city: 'Gelsenkirchen',
    state: 'Germany',
    nightNumber: 3,
    date: '07/19/2024',
    piano: true,
  },

  // Hamburg
  {
    songID: 'teardrops_on_my_guitar',
    mashup: "the_last_time",
    city: 'Hamburg',
    state: 'Germany',
    nightNumber: 1,
    date: '07/23/2024',
    guitar: true,
  },
  {
    songID: 'we_were_happy',
    mashup: 'happiness',
    city: 'Hamburg',
    state: 'Germany',
    nightNumber: 1,
    date: '07/23/2024',
    piano: true,
  },
  {
    songID: 'the_last_great_american_dynasty',
    mashup: 'run',
    city: 'Hamburg',
    state: 'Germany',
    nightNumber: 2,
    date: '07/24/2024',
    guitar: true,
  },
  {
    songID: 'nothing_new',
    mashup: 'dear_reader',
    city: 'Hamburg',
    state: 'Germany',
    nightNumber: 2,
    date: '07/24/2024',
    piano: true,
  },

  // Munich
  {
    songID: 'fresh_out_the_slammer',
    mashup: "you_are_in_love",
    city: 'Munich',
    state: 'Germany',
    nightNumber: 1,
    date: '07/27/2024',
    guitar: true,
  },
  {
    songID: 'ivy',
    mashup: 'call_it_what_you_want',
    city: 'Munich',
    state: 'Germany',
    nightNumber: 1,
    date: '07/27/2024',
    piano: true,
  },
  {
    songID: 'i_dont_wanna_live_forever',
    mashup: 'imgonnagetyouback',
    city: 'Munich',
    state: 'Germany',
    nightNumber: 2,
    date: '07/28/2024',
    guitar: true,
  },
  {
    songID: 'loml',
    mashup: 'dont_you',
    city: 'Munich',
    state: 'Germany',
    nightNumber: 2,
    date: '07/28/2024',
    piano: true,
  },


  // Warsaw
  {
    songID: 'mirrorball',
    mashup: "clara_bow",
    city: 'Warsaw',
    state: 'Poland',
    nightNumber: 1,
    date: '08/01/2024',
    guitar: true,
  },
  {
    songID: 'suburban_legends',
    mashup: 'new_years_day',
    city: 'Warsaw',
    state: 'Poland',
    nightNumber: 1,
    date: '08/01/2024',
    piano: true,
  },
  {
    songID: 'i_can_fix_him',
    mashup: 'i_can_see_you',
    city: 'Warsaw',
    state: 'Poland',
    nightNumber: 2,
    date: '08/02/2024',
    guitar: true,
  },
  {
    songID: 'red_song',
    mashup: 'maroon',
    city: 'Warsaw',
    state: 'Poland',
    nightNumber: 2,
    date: '08/02/2024',
    piano: true,
  },
    {
    songID: 'today_was_a_fairytale',
    mashup: 'i_think_he_knows',
    city: 'Warsaw',
    state: 'Poland',
    nightNumber: 3,
    date: '08/03/2024',
    guitar: true,
  },
  {
    songID: 'the_black_dog',
    mashup: 'exile',
    city: 'Warsaw',
    state: 'Poland',
    nightNumber: 3,
    date: '08/03/2024',
    piano: true,
  },


  // London - 2
  {
    songID: 'everything_has_changed',
    mashup: "end_game",
    mashup2: "thinking_out_loud",
    city: 'London',
    state: 'Poland',
    nightNumber: 4,
    date: '08/15/2024',
    guitar: true,
    note: "with Ed Sheeran"
  },
  {
    songID: 'king_of_my_heart',
    mashup: 'the_alchemy',
    city: 'London',
    state: 'England',
    nightNumber: 4,
    date: '08/15/2024',
    piano: true,
  },
  {
    songID: 'london_boy',
    city: 'London',
    state: 'England',
    nightNumber: 5,
    date: '08/16/2024',
    guitar: true,
  },
  {
    songID: 'dear_john',
    mashup: 'sad_beautiful_tragic',
    city: 'London',
    state: 'England',
    nightNumber: 5,
    date: '08/16/2024',
    piano: true,
  },
  {
    songID: 'my_boy_only_breaks_his_favorite_toys',
    mashup: 'coney_island',
    city: 'London',
    state: 'England',
    nightNumber: 6,
    date: '08/17/2024',
    piano: true,
  },
  {
    songID: 'i_did_something_bad',
    city: 'London',
    state: 'England',
    nightNumber: 6,
    date: '08/17/2024',
    guitar: true,
  },
  {
    songID: 'long_live',
    mashup: 'change',
    city: 'London',
    state: 'England',
    nightNumber: 7,
    date: '08/17/2024',
    guitar: true,
  },
  {
    songID: 'the_archer',
    mashup: 'youre_on_your_own_kid',
    city: 'London',
    state: 'England',
    nightNumber: 7,
    date: '08/17/2024',
    piano: true,
  },
  {
    // In the setlist
    songID: 'florida',
    note: "with Florence",
    city: 'London',
    state: 'England',
    nightNumber: 8,
    date: '08/17/2024',
  },
  {
    songID: 'death_by_a_thousand_cuts',
    note: "with Jack Antonoff",
    mashup: 'getaway_car',
    city: 'London',
    state: 'England',
    nightNumber: 8,
    date: '08/17/2024',
    guitar: true,
  },
  {
    songID: 'so_long_london',
    city: 'London',
    state: 'England',
    nightNumber: 8,
    date: '08/17/2024',
    piano: true,
  },
]


// https://www.taylorswift.com/tour/
/// https://en.wikipedia.org/wiki/The_Eras_Tour

const allSongIDs = Object.keys(entireCatalog)
SurpriseSongs.forEach(s => {
  if (!allSongIDs.includes(s.songID)) {
    throw new Error(`Song ${s.songID} is not a recongnized song`)
  }

  if (s.mashup && !allSongIDs.includes(s.mashup)) {
    throw new Error(`Song ${s.mashup} is not a recongnized song`)
  }

  if (s.mashup2 && !allSongIDs.includes(s.mashup2)) {
    throw new Error(`Song ${s.mashup2} is not a recongnized song`)
  }
})

export function getDate(dateString: string) {
  const [m, d, y] = dateString.split('/')
  return new Date(parseInt(y), parseInt(m), parseInt(d))
}

export const LastEntry = SurpriseSongs.sort((a, b) => {
  return getDate(a.date).getTime() - getDate(b.date).getTime()
}).reverse()[0]

export const SurpriseSongBySongID = SurpriseSongs.reduce(function (r, a) {
  r[a.songID] = r[a.songID] || [];
  r[a.songID].push(a);
  return r;
}, Object.create(null));


export const MashupsBySongID = SurpriseSongs.reduce(function (r, a) {
  if (a.mashup) {
    r[a.mashup] = r[a.mashup] || [];
    r[a.mashup].push(a);

    r[a.songID] = r[a.songID] || []
    r[a.songID].push(a)
  }

  if (a.mashup2) {
    r[a.mashup2] = r[a.mashup2] || [];
    r[a.mashup2].push(a);
  }

  return r;
}, Object.create(null));


export const CombinedPlayInstancesBySongId = SurpriseSongs.reduce(function (r, a) {
  r[a.songID] = r[a.songID] || [];
  r[a.songID].push(a);

  if (a.mashup) {
    r[a.mashup] = r[a.mashup] || [];
    r[a.mashup].push(a);
  }

  if (a.mashup2) {
    r[a.mashup2] = r[a.mashup2] || [];
    r[a.mashup2].push(a);
  }

  return r;
}, Object.create(null));


export const SurpriseToggleTypes = [
  "just2023",
  "just2024",
  "all",
] as const;

export type SurpriseToggleTypesCase = (typeof SurpriseToggleTypes)[number];

export const WithMashupToggleValues = [
  "withMashups",
  "withoutMashups",
] as const;

export type WithMashupsToggleTypes = (typeof WithMashupToggleValues)[number];

export const WithorWithoutMashupsString = {
  withMashups: ' including mashups',
  withoutMashups: ' without mashups'
}


export const IncludedSongsValues = [
  'setListSongs',
  'alreadyPlayedSongs',
  'playedLastYearSongs',
  'unplayedSongs',
]
export type IncludedSongsCase = (typeof IncludedSongsValues)[number]

export const SurpriseToggleDisplayStrings = {
  all: 'Combined',
  just2023: '2023',
  just2024: '2024',
}


export const PianoEmoji = '🎹'
export const GuitarEmoji = '🎸'
import * as React from "react";
import styled from 'styled-components';

const StyledUserSettings = styled.div`
    color: black;
`

const StyledTitle = styled.h1`
    font-weight: bold;
    font-size: 1.05em;
    margin-bottom: 4px;
`

export function AlbumDirectionContainer({
    randomOrder,
    updateUserSetting,
}: {
    randomOrder: boolean
    updateUserSetting: (setting: string, value: boolean) => void
}) {
    return <StyledUserSettings>
        <StyledTitle>Song order</StyledTitle>
        <form>
            <div onClick={() => updateUserSetting('randomOrder', false)}>
                <input
                    readOnly
                    type="radio"
                    name="text-alignment"
                    value="albumOrder"
                    checked={!randomOrder}
                />
                &nbsp;Album Order
            </div>
            <div onClick={() => updateUserSetting('randomOrder', true)}>
                <input
                    readOnly
                    type="radio"
                    name="text-alignment"
                    value="randomOrder"
                    checked={randomOrder}
                />
                &nbsp;Random
            </div>
        </form>
    </StyledUserSettings >
}
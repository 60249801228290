import * as React from "react";
import styled from 'styled-components';

const StyledUserSettings = styled.div`
    color: black;
`

const StyledTitle = styled.h1`
    font-weight: bold;
    font-size: 1.05em;
    margin-bottom: 4px;
`

export function UserSettingsContainer({
    leftToRight,
    updateUserSetting, 
}: {
    leftToRight: boolean
    updateUserSetting: (setting: string, value: boolean) => void
}) {
    return <StyledUserSettings>
        <StyledTitle>Lyric Direction</StyledTitle>
        <form>
            <div onClick={() => updateUserSetting('leftToRight', false)}>
                <input
                    readOnly
                    type="radio"
                    name="text-alignment"
                    value="upAndDown"
                    checked={!leftToRight}
                />
                &nbsp;Up and Down
            </div>
            <div onClick={() => updateUserSetting('leftToRight', true)}>
                <input
                    readOnly
                    type="radio"
                    name="text-alignment"
                    value="leftToRight"
                    checked={leftToRight}
                />
                &nbsp;Left to Right
            </div>
        </form>
    </StyledUserSettings >
}
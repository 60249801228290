// https://stackoverflow.com/questions/54542318/using-an-enum-as-a-dictionary-key
export const PageTypes = [
    "faq",
    "songGuesser",
    "changeLog",
    "surpriseSongs",
] as const;

export const TWITTER_LINK = 'https://twitter.com/tesshuelskamp/status/1675648635441414144?s=46'
export const LINKEDIN_LINK = 'https://www.linkedin.com/in/tesshuelskamp'

export type PageTypeCase = (typeof PageTypes)[number];

export const PageDestination: { [K in PageTypeCase] : string } = {
  "faq": "/faq",
  "songGuesser": "/",
  "surpriseSongs" : "/surprise_song_tracker",
  "changeLog": "/change_log",
}

export const PageDisplay: { [K in PageTypeCase]  : string } = {
  "faq": "FAQ",
  "surpriseSongs": "Surprise Song Tracker",
  "songGuesser": "Song Guesser",
  "changeLog": "Change Log",
}



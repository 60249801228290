import React, { useState } from 'react';

import styled from 'styled-components';
import { AlbumOrderForSurprise } from '../../Catalog/catalog';
import SurpriseAlbumContainer from './AlbumContainer';
import { LastEntry } from './data';
import { Footer } from '../../Footer';
import { SurpriseSettingsContainer } from './settings';
import { IncludedSongsContext, SurpriseDisplayContext, WithMashupContext } from './context';
import { IncludedSongsValues, SurpriseToggleDisplayStrings, SurpriseToggleTypesCase, WithMashupsToggleTypes, WithorWithoutMashupsString } from './consts';
import { getScreenWidth } from '../../Util/utils';
import { gaEvents } from '../../events';
import SEO from '../../SEO';
import { SEOData } from '../../SEO/data';
import { useCookies } from 'react-cookie';
import { useIsMobile } from '../../hooks/windowsize';


const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'main'
    'footer';

  overflow-y: none;
  margin: 0;
  font-family: Raleway, sans-serif;
`

const StyledAlbumContainer = styled.div<{
  isMobile: boolean
}>`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;

  ${({ isMobile}) => {
    if (isMobile) {
      return `height: calc(100vh - 80px - 10px);`
    } else {
      return `height: calc(100vh - 40px - 10px);`
    }
  }}

  grid-area: main;
`

const StyledFooterContainer = styled.div<{
  isMobile: boolean
}>`
  grid-area: footer;
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  ${({ isMobile}) => {
    if (isMobile) {
      return `height: 80px!important;`
    } else {
      return `height: 40px!important;`
    }
  }}
  padding: 5px;

  background-color: #C4CDD4;
  font-family: Raleway, sans-serif;
  font-size: 0.9em;
`

const StatusContainer = styled.div`
  padding-left: 5px;
  line-height: 18px;
`

const ToggleableNavBar = styled.div<{
  selected: boolean
}>`
  width: ${getScreenWidth()}px;
  box-sizing: border-box;
  position: fixed;
  bottom : -150%;
  padding: 20px;
  transition: all .5s;
  -webkit-transition: all .5s ease-in-out;

  border-radius: 30px 30px 0 0;
  max-height: 250px;

  ${({ selected }) => {
    // Do I need a display: hidden?
    if (selected) {
      return `bottom: 0;`
    }
  }}

  color: black;
  background-color: #C4CDD4;
`

const FooterMenuButton = styled.div`
  position: fixed;
  bottom 25px;
  right: 20px;
  z-index: 100;

  width: 50px;
  height: 50px;
  border-radius: 50%;
  
  display: flex;
  justify-content: center;
  align-items: center;

  // font-size: 2em;
  background-color: black;

  cursor: pointer;
  z-index: 1000;
  // box-shadow: 0 1rem 3rem rgba(182, 237, 200, 0.3);
  text-align: center;
`

const Icon = styled.div<{
  selected: boolean
}>`
  position: relative;
  color: #C4CDD4;
  background-color: #C4CDD4;
  width: 2rem;
  height: 2px;
  display: inline-block;
  margin: 4px;
  transition: all 0.5s;

  &::before, &::after {
    content: "";
    background-color: #C4CDD4;
    width: 2rem;
    height: 2px;
    position: absolute;
    left: 0;
    transition: all 0.5s;
  }

  ${({ selected }) => {
    if (selected) {
      return `
      background-color: black;
      &::before {
        top: 0;
        transform: rotate(135deg);
      }
      &::after {
        top: 0;
        transform: rotate(-135deg)};
      }
      `
    } else {
      return `
        &::before {
          top: -0.8rem;
          transform: rotate(0);
        }
        &::after {
          top: 0.8rem;
          transform: rotate(0);
      }`
    }
  }}
`;

function PrettySurpriseSongs() {
  const [cookies, setCookies]= useCookies(['surpriseSongSetting', 'withMashupsSetting'])
  const initialSurpriseSettingState = cookies.surpriseSongSetting === undefined ? 'all' : cookies.surpriseSongSetting as SurpriseToggleTypesCase
  const [surpriseSongSetting, setSurpriseSongSetting] = useState(initialSurpriseSettingState)


  const initialWithMashupSetting = cookies.surpriseSongSetting === undefined ? 'withMashups' : cookies.withMashupsSetting as WithMashupsToggleTypes
  const [withMashupSetting, setWithMashupSetting] = useState(initialWithMashupSetting)

  const [settingsNavBarEnabled, setSettingsNavBarEnable] = useState(false)
  const [excludedSongs, setExcludedSongs] = useState(IncludedSongsValues)

  const updateSurpriseSongSetting = (newSetting: SurpriseToggleTypesCase) => {
    gaEvents.logSurpriseSongSettingClicked(newSetting)
    setCookies('surpriseSongSetting', newSetting)
    setSurpriseSongSetting(newSetting)
  }

  const updateWithMashupsSetting = (newSetting: WithMashupsToggleTypes) => {
    setCookies('withMashupsSetting', newSetting)
    setWithMashupSetting(newSetting)
  }

  const isMobile = useIsMobile()

  const toggleSettingsBar = () => {
    if (!settingsNavBarEnabled) {
      gaEvents.logSurpriseMobilePopupClicked()
    }
    setSettingsNavBarEnable(!settingsNavBarEnabled)
  }

  const albums = AlbumOrderForSurprise
                    .filter(aID => {
                            if (surpriseSongSetting === 'just2023') {
                              return aID !== 'ttpd'
                            } else {
                              return true
                            }
                          }
                    )
  
  return <MainContainer>
    <SEO seoObject={SEOData.surpriseSongs}/>

    <SurpriseDisplayContext.Provider value={surpriseSongSetting}>
    <IncludedSongsContext.Provider value={excludedSongs}>
    <WithMashupContext.Provider value={withMashupSetting}>
      
      <StyledAlbumContainer isMobile={isMobile}>
        {
          albums.map(albumID =>
            <SurpriseAlbumContainer
                key={albumID}
                albumID={albumID}
            />
          )
        }
      </StyledAlbumContainer>

      <StyledFooterContainer isMobile={isMobile}>
          <StatusContainer>
            Up to date through {LastEntry.city} N{LastEntry.nightNumber} { !isMobile && <>({LastEntry.date})</>}
            { isMobile && <><br/><span>Currently showing {SurpriseToggleDisplayStrings[surpriseSongSetting]} Songs</span></>}
          </StatusContainer>
          { !isMobile && <span>Currently showing {SurpriseToggleDisplayStrings[surpriseSongSetting]} Surprise Songs {WithorWithoutMashupsString[withMashupSetting]}</span> }
          <Footer currentPage='surpriseSongs' mobileVersion={isMobile}/>
      </StyledFooterContainer>
 
      <FooterMenuButton onClick={() => toggleSettingsBar()}>
        <Icon selected={settingsNavBarEnabled}/>
      </FooterMenuButton>
      <ToggleableNavBar selected={settingsNavBarEnabled}>
        <SurpriseSettingsContainer
          isMobile={isMobile}
          toggleSettingsBar={toggleSettingsBar}
          updateSurpriseSongSetting={updateSurpriseSongSetting}
          updateWithMashupsSetting={updateWithMashupsSetting}
          setExcludedSongs={setExcludedSongs}
        />
      </ToggleableNavBar>
    
    </WithMashupContext.Provider>
    </IncludedSongsContext.Provider>
    </SurpriseDisplayContext.Provider>
  </MainContainer>
} export default PrettySurpriseSongs;
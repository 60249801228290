import * as React from 'react';
import { useState, useEffect, useRef } from 'react'
import styled from 'styled-components';
import { gridColumnGap } from '../Style';


import { sanitizeInput } from '../Util/utils'
import { getNumberOfRows } from './util';
import { Word, Status } from './Word'
import { useIsMobile } from '../hooks/windowsize';


const StyledSongContainer = styled.div`
`

const StyledMainContainer = styled.div`
    grid-area: main;
    overflow-x: hidden;
`

const StyledTitle = styled.h1`
    font-size: 1.25em;
    font-style: italic;
    
    display: flex;
    justify-content: center;
`

const MobileKeyboardBlock = styled.div`
    height: 300px;
`

const StyledLyricContainer = styled.div<{
    isMobile: boolean,
    leftToRight: boolean,
    numRows: number
}>`
${({ isMobile, leftToRight, numRows }) => {
        if (!isMobile && leftToRight) {
            return `
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 2px;
                row-gap: 10px;
                max-width: 2000px;
                padding: 30px;
            `
        } else if (!isMobile && !leftToRight) {
            return `
                display: grid;
                grid-auto-flow: column;
                grid-row-gap: 0px;
                grid-column-gap: ${gridColumnGap}px;
            
                justify-items: center;
                justify-content: center;
                grid-template-rows: repeat(${numRows}, 1fr);
                margin: 10px;
                margin-bottom: 30px;
            `
        } else {
            return `
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 4px;
                row-gap: 10px;
                max-width: 2000px;
            `
        }
    }}
`

// https://www.react-reveal.com/examples/common/bounce/

export function LyricContainer({
    lyrics,
    guessedWords,
    title,
    showTitle,
    leftToRight,
    revealAlbum,
    albumTitle,
    markWordAsSeen,
}: {
    lyrics: string[],
    guessedWords: { [id: string]: Status; }
    title: string,
    showTitle: boolean
    leftToRight: boolean
    revealAlbum: boolean
    albumTitle: string
    markWordAsSeen: (word: string) => void
}) {
    const [width, setWidth] = useState(0)
    const ref = useRef(null)
    const isMobile = useIsMobile()

    useEffect(() => {
        const handleResize = () => {
            setWidth(ref.current.offsetWidth);
        };
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    const numRows = getNumberOfRows(width, lyrics.length)

    return (
        <StyledMainContainer>
            <StyledSongContainer ref={ref}>
                <StyledTitle>
                    <div>
                        {showTitle ? title : ''}&nbsp;
                        {(revealAlbum && !showTitle) && albumTitle}
                    </div>
                </StyledTitle>
                <StyledLyricContainer
                    isMobile={isMobile}
                    numRows={numRows}
                    leftToRight={leftToRight}
                >
                    {lyrics.map((word, i) =>
                        <Word
                            markWordAsSeen={markWordAsSeen}
                            key={i}
                            word={word}
                            isMobile={isMobile}
                            status={guessedWords[sanitizeInput(word)]}
                        />
                    )}
                </StyledLyricContainer>
                {isMobile && <MobileKeyboardBlock>&nbsp;</MobileKeyboardBlock>}
            </StyledSongContainer>
        </StyledMainContainer>
    )
}
import styled from 'styled-components';
import { PageDestination, PageDisplay, PageTypeCase, PageTypes } from './data';

const StyledFooter = styled.div`
  grid-area: footer;

  display: flexbox;
  justify-content: flex-end;
  text-align: right;
`

const StyledFooterEntry = styled.div<{
    darkMode: boolean
}>`
    color: grey;
    font-style: italic;
    padding-right: 15px;

    > a {
        color: #2986cc;
    }

    // lol this could be worse.  I should figure out how to make this be an ACTUAL footer
    ${({ darkMode }) => { 
        if (darkMode) {
            return `
                color: white;
                > a {
                    color: white;
                }
            `
        }
    }}
`

export function Footer({
    currentPage="songGuesser",
    mobileVersion=false,
}: {
    currentPage?: PageTypeCase
    mobileVersion?: boolean
}) {
    const footerLinks = PageTypes.filter(p => p !== currentPage)
    const darkMode = currentPage === "changeLog"

    return <StyledFooter>
        <StyledFooterEntry darkMode={darkMode}>
            {
                !mobileVersion && <>
                    Made with 💗 by <a target="_blank" href="https://twitter.com/tesshuelskamp/status/1675648635441414144?s=46" rel="noreferrer">Tess Huelskamp</a>
                    { currentPage !== 'surpriseSongs' &&
                    <>
                        &nbsp;<span style={{ "fontStyle": "normal" }}>|</span> &copy; 2023-2024
                    </>
                    }
                    <br/>
                </>
            }
            {
                footerLinks.map((page, idx) => <span key={page}>
                            {idx !== 0 && <span style={{ "fontStyle": "normal" }}> | </span>}
                            <a href={PageDestination[page]}>{PageDisplay[page]}</a>
                        </span>
                )
            }
            {
                currentPage == "songGuesser" && <div>
                    <br/>
                    Hey! I added all of the TTPD songs pretty quickly
                    <br/>so pls lmk if you run into any lyrics issues :&#41;
                    <br/>tess DOT huelskamp AT gmail DOT com</div>
            }
        </StyledFooterEntry>
    </StyledFooter >
}
import { gridColumnGap, lyricContainerPixels } from "../Style"

const largestBrowserSize = 2880;
const padding = 50;
const minimumRows = 25

export function getNumberOfRows(width: number, numWords: number) {
    const columnWidth = gridColumnGap + lyricContainerPixels;

    const maxPlayAreaWidth = Math.min(width, largestBrowserSize) - padding
    const numCols = Math.floor(maxPlayAreaWidth / columnWidth) - 1

    return Math.max(Math.floor(numWords / numCols) + 1, minimumRows)
}
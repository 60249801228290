import ReactGA from "react-ga4"

const songCompleted =
    (trackName: string, numRemaining: number) =>
        ReactGA.event(
            'song_completed',
            {
                trackName: trackName,
                numRemaining: numRemaining
            }
        )

const individualWordRevealed =
    (trackName: string, word: string) =>
        ReactGA.event(
            'individual_word_revealed',
            {
                trackName: trackName,
                word: word
            }
        )

const albumsUpdated =
    ( albums: string[]) => 
        ReactGA.event(
            'albums_updated',
            {albums: albums}
        )

const songTitleFullyGuessed =
        (trackName: string) =>
            ReactGA.event(
            'song_title_fully_guessed',
            { trackName: trackName }
        )

const songWon =
    (trackName: string) =>
        ReactGA.event(
            'song_won',
            { trackName: trackName }
        )

const songGivenUp =
    (trackName: string, numRemaining: number) =>
        ReactGA.event(
            'song_given_up',
            {
                trackName: trackName,
                numRemaining: numRemaining
            }
        )

const selectAllAlbumsClicked = () => {
    ReactGA.event(
        'all_albums_clicked'
    )
}

const selectErasTourClicked = () => {
    ReactGA.event(
        'eras_tour_clicked'
    )
}

const surpriseSettingUpdated = (setting: string) => {
    ReactGA.event(
        'surprise_song_setting_updated',
        {
            newSetting: setting
        }
    )
}

const surpriseToolTipClicked = (songID: string) => {
    ReactGA.event(
        'surprise_tool_tip_clicked',
        {
            song: songID
        }
    )
}


const songStarted =
    (trackName: string) =>
        ReactGA.event(
            'song_started',
            { trackName: trackName }
        )

const surpriseMobilePopupClicked = () => {
    ReactGA.event(
        'surprise_mobile_popup_clicked'
    )
}

const includedSurpriseSongsUpdated =
    ( includedSongs: string[], currentSetting: string) =>
        ReactGA.event(
            'mobile_included_songs_updated',
            {
                includedSongs: includedSongs,
                currentSetting: currentSetting,
            }
        )

const possibleFuzzyCollision = (guess: string, word1: string, word2: string) =>
            ReactGA.event(
                'possible_fuzzy_collision',
                { collision: guess + "-" + word1 + "-" + word2 }
            )

const fuzzyMatch = (guess: string, match: string) =>
            ReactGA.event(
                'fuzzy_match',
                { guessString: guess+'-'+match }
            )

const gaEvents = {
    logSongCompleted: songCompleted,
    logSongStarted: songStarted,
    logSongGivenUp: songGivenUp,
    logSongWon: songWon,
    logAlbumsUpdated: albumsUpdated,
    logSelectAllAlbumsClicked: selectAllAlbumsClicked,
    logSelectErasTourClicked: selectErasTourClicked,
    logIndividualWordRevealed: individualWordRevealed,
    logSongTitleFullyGuessed: songTitleFullyGuessed,
    logSurpriseSongSettingClicked: surpriseSettingUpdated,
    logSurpriseToolTipClicked: surpriseToolTipClicked,
    logSurpriseMobilePopupClicked: surpriseMobilePopupClicked,
    logIncludedSurpriseSongsUpdated: includedSurpriseSongsUpdated,
    logPossibleFuzzyCollision: possibleFuzzyCollision,
    logFuzzyMatch: fuzzyMatch,
}

export { gaEvents }